import { Component,  OnInit } from '@angular/core';
import { ReplacementFieldsBaseQuestionComponent } from 'src/app/platform-question/question-types/base-question/base-question.component';
import { MultiDropdownsPipe } from 'src/app/shared/pipes/multi-dropdowns.pipe';

@Component({
  selector: 'app-multiple-dropdowns-question-previewer',
  templateUrl: './multiple-dropdowns-question.component.html',
  styleUrls: ['./multiple-dropdowns-question.component.scss']
})
export class MultipleDropdownsQuestionPreviewerComponent extends ReplacementFieldsBaseQuestionComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
    this.formString = new MultiDropdownsPipe()
      .transformForPreviewWithCorrectValues(this.formStringParts, this.replacementVariables, this.multipleForm, this.question$.value);
  }

  formListener(): void {}

  handleSubmit() {}

  getElements(): any[] {
    const form = this.customForm.nativeElement;
    return [...form.querySelectorAll('select')];
  }
}
