import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class GlobalLoaderService {
  isLoading = new Subject<boolean>();
  loadingMessage = new Subject<string>();
  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }
  hideLoading() {
    this.loadingMessage.next(null);
  }
}
