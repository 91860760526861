import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { QuestionPreviewerService } from '../../../services/question-previewer/question-previewer.service';
import {Answer, Question} from '../../../models/question';
import { GlobalLoaderService } from '../../../services/global-loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import {SubmittedAnswerAnswer, SubmittedAnswerResult} from '../../../models/submitted-answer-result';
import {concatMap, finalize, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-question-previewer',
  templateUrl: 'lms-question-previewer.component.html',
  styleUrls: ['question.component.scss']
})

export class LmsQuestionPreviewerComponent implements OnInit {
  question: Question;
  courseId: number;
  quizId: number;
  questionId: number;
  answerResult: SubmittedAnswerResult;

  constructor(
    private route: ActivatedRoute,
    private questionPreviewerService: QuestionPreviewerService,
    private globalLoaderService: GlobalLoaderService
  ) {}

  ngOnInit(): void {
    // this.globalLoaderService.show();
    const routeParams$ = this.route.params;
    routeParams$.pipe(
      map(params => {
        this.courseId = params.courseId;
        this.quizId = params.quizId;
        this.questionId = params.questionId;
      }),
      concatMap(() => this.questionPreviewerService.getQuestionForPreview(this.courseId, this.quizId, this.questionId)),
      tap(data => this.question = data),
      tap(() => this.setCorrectAnswer())
    ).subscribe();
    // this.globalLoaderService.hide();
  }

  // Will need to be updated if we move to questions that have multiple correct answers for partial credit. This works for single answer
  // multiple choice and T&F, though.
  getCorrectAnswerIndex(): number {
    return this.question.answers.find(answer => answer.weight === 100).id;
  }

  setCorrectAnswer(): void {
    const correct = this.question.answers.find(answer => answer.weight === 100);
    if (correct) {
      const submittedAnswer = new SubmittedAnswerAnswer();
      submittedAnswer.answer = correct.id.toString();
      this.answerResult = new SubmittedAnswerResult();
      this.answerResult.answer = submittedAnswer;
      this.answerResult.correctComments = correct.commentsHtml;
      this.answerResult.isCorrect = true;
      this.answerResult.allowReattempt = false;
    }
    this.question.canAnswer = false;
  }

  getExplanation(submissionValue: number): string {
    return this.question.answers[submissionValue].commentsHtml;
  }

  getAnswerText(answer: Answer) {
    if (answer.html !== '') {
      return answer.html;
    }
    if (answer.text !== '') {
      return answer.text;
    }
  }
}
