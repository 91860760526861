import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss']
})
export class ButtonLoaderComponent {
  // Todo create a service so the message can be updated.
  @Input() message = 'Please wait while we submit your response';
  constructor() {}
}
