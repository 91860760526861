<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>
<form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <label for="questionAnswer" class="visually-hidden">Your answer:</label>
    <input
      id="questionAnswer"
      type="number"
      [(ngModel)]="questionAnswer"
      required
      name="questionAnswer"
      [value]="this.answerResult$.value != null ? this.answerResult$.value.answer : null"
      [disabled]="this.answerResult$.value != null || !this.questionLoaded"
  >
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
  ></ng-template>
  <br><br>
  <ng-container *ngIf="canShowCorrectAnswer() && (question$ | async).valueOf()">
    The correct answer is:
    <div class="alert alert-success correct-answer">
      <div *ngFor="let correctAnswer of question$.value.correctAnswers">
        <div *ngIf="correctAnswer.numericalAnswerType == 'exact_answer'">
          {{correctAnswer.exact}}, exactly.
        </div>
        <div *ngIf="correctAnswer.numericalAnswerType == 'range_answer'">
          A range between {{correctAnswer.start}} and {{correctAnswer.end}}.
        </div>
        <div *ngIf="correctAnswer.numericalAnswerType == 'precision_answer'">
          Approximately {{correctAnswer.approximate}} with a precision of {{correctAnswer.precision}}
        </div>
      </div>
    </div>
  </ng-container>
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !submissionForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
