<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>

<form [formGroup]="multipleAnswersForm" (ngSubmit)="handleSubmit()">
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <fieldset [disabled]="!questionLoaded || this.answerResult != null">
      <legend class="visually-hidden">Answer:</legend>
      <div>
        <ng-container *ngFor="let order of answersFormArray.controls; let i = index">
          <label formArrayName="answers" >
            <input id="field-{{useQuestion.answers[i].id}}" type="checkbox" [formControlName]="i">
            <span *ngIf="!useQuestion.answers[i].html">
              {{useQuestion.answers[i].text}}
            </span>
            <span *ngIf="useQuestion.answers[i].html !== null"
              [innerHTML]="removeFirstParagraphTag(useQuestion.answers[i].html)">
            </span>
          </label>
          <ng-container *ngIf="answerResult$.value != null">
            <div *ngIf="answerHasComments(useQuestion.answers[i].id)">
              <div
                class="answer-comments alert alert-info"
                [innerHTML]="answerComments(useQuestion.answers[i].id)">
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="canShowCorrectAnswer()">
          <div class="correct-answer-container">
            The following options comprise the correct answer
            <div class="alert alert-success correct-answer" [innerHTML]="correctAnswerText() | safeHtml"></div>
          </div>
        </ng-container>
      </div>
  </fieldset>
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
  ></ng-template>
<hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult != null || !multipleAnswersForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
