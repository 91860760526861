import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-quiz-completion',
  templateUrl: './quiz-completion.component.html',
  styleUrls: ['./quiz-completion.component.scss']
})
export class QuizCompletionComponent {

  public profileUrl = `${environment.learnerPortalUrl}/user-profile`;

  constructor() {}

}
