import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question} from '../../../models/question';

import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray, ValidatorFn } from '@angular/forms';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {MultipleIntQuestionAnswer} from '../../../models/answered-question';

@Component({
  selector: 'app-multiple-answers-question',
  templateUrl: './multiple-answers-question.component.html',
  styleUrls: ['./multiple-answers-question.component.scss']
})
export class MultipleAnswersQuestionComponent extends BaseQuestionComponent implements OnInit {
  multipleAnswersForm: UntypedFormGroup;

  get answersFormArray() {
    return this.multipleAnswersForm.controls.answers as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  setAnswer() {
    const controls = this.answersFormArray;
    const answers = Array.from(this.answerResult$.value.answer);
    answers.forEach(a => {
      let index;
      let lookup;
      if (typeof a === 'string') {
        lookup = parseInt(a, 10);
      }
      if (typeof a === 'number') {
        lookup = a;
      }
      index = this.questionDisplay.answers.map(look => look.id).indexOf(lookup);
      controls.controls[index].patchValue(true);
    });
  }

  correctAnswerText(): string {
    const answers = [];
    this.question$.value.correctAnswers.map(i => {
      answers.push(i.html !== '' && i.html !== null ? i.html : i.text);
    });

    return answers.join('<br> ');
  }

  ngOnInit() {
    super.ngOnInit();
    this.multipleAnswersForm = this.formBuilder.group({
      answers: new UntypedFormArray([], this.minSelectedCheckboxes(1))
    });
    this.createForm();
  }

  handleSubmit(): void {
    this.toggleQuestionSubmitting();
    const selectedAnswers = this.multipleAnswersForm.value.answers
      .map((checked, i) => checked ? this.question.answers[i].id : null)
      .filter(v => v !== null);
    const answer = new MultipleIntQuestionAnswer();
    answer.value = selectedAnswers;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }

  createForm() {
    this.questionDisplay.answers.forEach(answer => {
      this.answersFormArray.push(new UntypedFormControl(false));
    });
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  removeFirstParagraphTag(htmlString: string): string {
    const str = htmlString.replace(/<p>(.*?)<\/p>/, '$1');
    return ' ' + str;
  }
}
