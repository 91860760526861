import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ReplacementVariable} from '../base-question/base-question.component';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {
  @Input() replacement: ReplacementVariable;
  @Input() form!: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
  }

}
