import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question} from '../../models/question';
import {SubmittedAnswerResult} from '../../models/submitted-answer-result';
import {PlatformQuestionFeedbackComponent} from '../platform-question-feedback/platform-question-feedback.component';
import {IQuestionAnswer} from '../../models/answered-question';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject} from 'rxjs';
import {QuestionService} from '../../services/questions/question.service';
import {environment} from "../../../environments/environment";
import {ApplicationInsightsLoggingService} from "../../services/application-insights-logging.service";
import {tap} from "rxjs/operators";
import {QuestionViewSessionManagerService} from "../../services/question-view-session-manager.service";

@Component({
  selector: 'app-platform-question-display',
  templateUrl: './platform-question-display.component.html',
  styleUrls: ['./platform-question-display.component.scss']
})
export class PlatformQuestionDisplayComponent implements OnInit {
  @Input() questionDisplay: Question;
  @Input() isDemo: boolean;
  @Input() slug: string;
  @Input() attemptNo: number;
  @Input() newView: boolean;
  @Input() question$: BehaviorSubject<Question>;
  @Input() answerResultRetrieved$: BehaviorSubject<boolean>;
  @Input() answerResult$: BehaviorSubject<SubmittedAnswerResult>;
  @Input() loadingMessage$: BehaviorSubject<string>;
  @Input() questionSubmissionError$: BehaviorSubject<boolean>;
  @Input() isExpired$: BehaviorSubject<boolean>;
  @Input() expirationDate$: BehaviorSubject<string>;
  @Input() courseDashboardUrl: string;
  @Input() countView = true;
  @Output() answerSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() questionSet: EventEmitter<any> = new EventEmitter<any>();
  @Output() answerResultSet: EventEmitter<any> = new EventEmitter<any>();
  @Output() initiateReattempt: EventEmitter<any> = new EventEmitter<any>();
  // @Output() setAnswerResultRetrieved: EventEmitter<any> = new EventEmitter<any>();
  feedbackSubmitted = false;
  question: Question;
  catalogUrl = environment.catalogUrl;
  supportUrl = `${this.catalogUrl}info/help#contact-form`;

  constructor(
    private modalService: NgbModal,
    private questionService: QuestionService,
    private questionViewSession: QuestionViewSessionManagerService) {}

  ngOnInit(): void {
    if (this.isDemo) {
      this.setDemoQuestion();
    }
  }

  sendViewAndGo(path: string): void {
    if (!this.isDemo && this.countView) {
      localStorage.setItem('_nV', '1');
      this.questionViewSession.sendView(this.slug).subscribe();
    }
    window.location.href = path;
  }

  isAnswerableQuestion() {
    return this.questionDisplay.questionType !== 'text_only_question';
  }

  handleSubmit(answerSent: IQuestionAnswer): void {
    this.answerSubmitted.emit(answerSent);
  }

  needsGrading() {
    return [
      'essay_question',
      'file_upload_question'
    ].includes(this.question$.value.questionType);
  }

  // We can't use the method on the Question because that is setup to handle legacy :/
  reAttemptAvailable() {
    return this.answerResult$.value != null
      && !this.answerResult$.value.isCorrect
      && this.answerResult$.value.allowReattempt
      && this.answerResult$.value.remainingAttempts > 0
      && this.question$.value != null
      && this.question$.value.canAnswer === true;
  }

  previousAttemptPath() {
    return `${window.location.pathname}?attempt=1`;
  }

  hasAcknowledgeComments(): boolean {
    return ((this.answerResult$.value.neutralComments !== '' && this.answerResult$.value.neutralComments !== null)
      || (this.answerResult$.value.questionIncorrectComments !== '' && this.answerResult$.value.questionIncorrectComments !== null)
      || (this.answerResult$.value.questionCorrectComments !== '' && this.answerResult$.value.questionCorrectComments !== null));
  }

  openFeedbackModal() {
    const modalOptions = {
      size: 'lg',
      windowClass: 'quiztime-modal'
    };
    // Cover the rare case of someone opening two questions at once, and trying to submit
    // feedback for the first question after opening the second...
    localStorage.setItem('platform-user-slug', this.slug);
    const modalRef = this.modalService.open(PlatformQuestionFeedbackComponent, modalOptions);
    modalRef.result.then((data) => {
      if (data === 'submit') {
        this.feedbackSubmitted = true;
      }
    });
  }

  setDemoQuestion() {
    this.question$.next(this.questionDisplay);
    this.questionSet.emit(this.questionDisplay);
    this.answerResult$.next(null);
    this.answerResult$.next(null);
    this.answerResultSet.emit(null);
    this.answerResultRetrieved$.next(true);
  }

  reattemptGo() : void {
    this.initiateReattempt.emit()
  }

  debugHelper(value = null) {
    return typeof(value);
  }
}
