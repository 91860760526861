import {LinkInterface} from '../../models/link.interface';
import {Injectable} from '@angular/core';

/**
 * TODO https://stackoverflow.com/questions/60212661/can-i-use-a-conditional-type-on-a-function-to-indicate-a-return-value-of-boolean
 */
@Injectable()
export class HateOasService {
  getLinkInterfaceForRel(links: LinkInterface[], targetRel: string): LinkInterface | undefined {
    return links.find(el => el.rel.toLowerCase() === targetRel.toLowerCase());
  }
}
