<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>

<!--{{ question | json }}-->
<form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <fieldset [disabled]="questionLoaded !== true || !useQuestion.canAnswer || (questionLoaded === true && answerResult$.value !== null)">
      <legend class="visually-hidden">Choose:</legend>
      <div class="form-check" *ngFor="let answer of useQuestion.answers; index as id">
        <input
          [(ngModel)]="questionAnswer"
          required
          [ngClass]="{
          'form-check-input': true,
          'is-correct-answer' : canShowCorrectAnswer() && useQuestion.correctAnswers[0]?.id == answer.id && useQuestion.platformQuizType != 'survey'
        }"
          name="questionAnswer"
          type="radio"
          id="choice-{{ id }}"
          [value]="answer.id"
        />
        <label
          class="form-check-label"
          for="choice-{{ id }}"
          [innerHTML]="getAnswerText(answer)">
        </label>
        <ng-container *ngIf="answerResult$.value != null">
          <div *ngIf="answerHasComments(answer.id)">
            <div *ngIf="answerResult$.value.answerDetails[0].commentsHtml != '' && answerResult$.value.answerDetails[0].commentsHtml !== null">
              <div
                class="answer-comments alert alert-info"
                [innerHTML]="answerComments(answer.id)">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </fieldset>
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
  ></ng-template>
  <hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !submissionForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
