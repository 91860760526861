<ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
  <label for="questionAnswer">
    <div class="visually-hidden">Answer:</div>
  </label>
  <input
  type="text"
  ngModel
  required
  id="questionAnswer"
  name="questionAnswer"
  [disabled]="true"
  [value]=""
>
<p></p><i>Accepted answers for this question:</i><p></p>
<div *ngFor="let answer of useQuestion.answers; index as id">
{{getAnswerText(answer)}}
  <div
    class="answer-comments alert alert-success"
    *ngIf="((answer.comments !== null && answer.comments !== '') || (answer.commentsHtml !== null && answer.commentsHtml !== ''))"
    [innerHTML]="answer.commentsHtml != '' && answer.commentsHtml != null ? answer.commentsHtml : answer.comments | safeHtml">
  </div>
</div>
</ng-template>
<ng-template
  [ngTemplateOutlet]="questionFormTemplate"
  [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
></ng-template>
