import {Component} from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {StringQuestionAnswer} from '../../../models/answered-question';

@Component({
  selector: 'app-text-only-question',
  templateUrl: './text-only-question.component.html',
  styleUrls: ['./text-only-question.component.scss']
})
export class TextOnlyQuestionComponent extends BaseQuestionComponent {
  handleSubmit() {
    this.toggleQuestionSubmitting();
    const answer = new StringQuestionAnswer();
    answer.value = 'acknowledged';
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }

  setAnswer() {}
}
