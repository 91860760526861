<div class="card">
  <div class="card-header">
    <div class="card-title">Manage your email preferences</div>
  </div>
  <div class="card-body">
    <div class="card-content">
      <div class="text-center question-complete alert alert-success" role="alert" *ngIf="preferencesUpdated">
        <p>Your preferences have been updated.</p>
      </div>
      <div class="text-center question-complete alert alert-danger" role="alert" *ngIf="lookupError != undefined">
        <p>{{ lookupError }}</p>
      </div>
      <app-email-preferences-lookup
        *ngIf="emailPreferences == undefined"
        (emailLookup)="emailLookupSubmit($event)">
      </app-email-preferences-lookup>
      <app-email-preferences-form
        *ngIf="emailPreferences !== undefined"
        [emailPreferences]="emailPreferences"
        [email]="userEmail"
        (preferencesUpdated)="emailPreferencesSubmit()">
      </app-email-preferences-form>
    </div>
  </div>
</div>
