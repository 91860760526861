import {Injectable, Injector} from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap } from "rxjs/operators";
import { QuestionPreviewerService } from '../../../services/question-previewer/question-previewer.service';
import { HateOasService } from '../../../shared/utilities/hateoas';
import { Question } from '../../../models/question';
import { GlobalLoaderService } from '../../../services/global-loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsightsLoggingService } from 'src/app/services/application-insights-logging.service';
import { QuestionComponent } from './question.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-question-previewer',
  templateUrl: 'question.component.html',
  styleUrls: ['question.component.scss']
})
export class QuestionPreviewerComponent extends QuestionComponent implements OnInit {
  question: Question;
  httpError: HttpErrorResponse = undefined;
  constructor(
    private route: ActivatedRoute,
    private questionPreviewerService: QuestionPreviewerService,
    private globalLoaderService: GlobalLoaderService,
    private hateoas: HateOasService,
    private logging: ApplicationInsightsLoggingService,
    domSanitizer: DomSanitizer
  ) {
    super(domSanitizer);
  }

  ngOnInit(): void {
    this.globalLoaderService.show();
    const routeParams$ = this.route.params;
    routeParams$.pipe(
      // Get QuestionID from params.
      map(params => {
        this.logging.logPageView(params.questionId)
        return params.questionId;
      }),
      // Get and set the Question for preview
      // This is where QuizTime 1.0 redirects a preview request to, with QuestionID as a parameter
      // Even though it is a request from QuizTime 1.0, we are still pulling question data with Core API
      mergeMap((questionId) => this.questionPreviewerService.getQuestionForLegacyPreview(questionId)),
      map(question => {
            this.setQuestionObject(question);
            return question;
          })
    ).subscribe(() => this.globalLoaderService.hide());
  }

  setQuestionObject(question: Question) {
    const newQuestion = new Question(this.hateoas);
    Object.assign(newQuestion, question);
    this.question = newQuestion;
    this.question.thisSubmissionValue = this.question.correctAnswer;
    super.ngOnInit();
  }
}
