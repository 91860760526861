import {Component, Input, OnInit} from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {FileUploadAnswer} from '../../../models/answered-question';
import {QuestionService} from '../../../services/questions/question.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-file-upload-question',
  templateUrl: './file-upload-question.component.html',
  styleUrls: ['./file-upload-question.component.scss']
})
export class FileUploadQuestionComponent extends BaseQuestionComponent implements OnInit {
  file: File;
  fileSubmissionId = 0;
  error = '';
  @Input() slug: string;
  constructor(private questionService: QuestionService) {
    super();
  }
  ngOnInit() {
    super.ngOnInit();
  }
  setAnswer() {
    if (this.answerResult != null) {
      this.fileSubmissionId = this.answerResult.answer;
    }
  }
  handleSubmit(value, isValid) {
    this.toggleQuestionSubmitting();
    const uploadedFile$ = this.questionService.uploadFileForAnswer(this.slug, this.file)
      .pipe(
        map((id: number) => {
          if (id === 0) {
            this.error = 'Unable to upload file, please try again or contact support';
          } else {
            this.fileSubmissionId = id;
          }
        })
      );
    uploadedFile$.subscribe(() => {
      if (this.fileSubmissionId > 0) {
        const answer = new FileUploadAnswer();
        answer.value = this.fileSubmissionId;
        setTimeout(() => {
          this.answerSubmitted.emit(answer);
        }, 1);
      }
    });
  }
  onFileSelected(event) {
    this.file = event.target.files[0];
  }
}
