<form #multiForm id="multiform">
  <div *ngIf="!showFullQuestion()" [innerHTML]="questionDisplay.questionText | safeHtml"></div>
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <div *ngIf="questionLoaded" id="form-replacement-field" #formReplacementField [innerHTML]="formString | safeHtml"></div>
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
  ></ng-template>
  <ng-container *ngIf="question$.value != null && canShowCorrectAnswer()">
    <div class="correct-answer-container">
      The correct answer is:
      <div class="alert alert-success correct-answer" [innerHTML]="correctAnswerString() | safeHtml"></div>
    </div>
  </ng-container>
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !formIsValid"
      (click)="handleSubmit()"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
