import { SubmittedAnswerAnswer, SubmittedAnswerResult } from '../../../../models/submitted-answer-result';
import { Component, OnInit } from '@angular/core';
import { BaseQuestionComponent } from '../../../question-types/base-question/base-question.component';

@Component({
  selector: 'app-numeric-question-previewer',
  templateUrl: './numeric-question.component.html',
  styleUrls: ['./numeric-question.component.scss']
})
export class NumericQuestionPreviewerComponent extends BaseQuestionComponent {
  handleSubmit() {}
}
