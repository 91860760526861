import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../services/feedbacks/feedback.service';
import { PlatformQuestionFeedback } from '../../models/platform-question-feedback.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-platform-question-feedback',
  templateUrl: './platform-question-feedback.component.html',
  styleUrls: ['./platform-question-feedback.component.scss']
})

export class PlatformQuestionFeedbackComponent implements OnInit {

  feedback: PlatformQuestionFeedback;
  slug: string;

  constructor(
    private feedbackService: FeedbackService,
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.slug = localStorage.getItem('platform-user-slug');
    this.feedbackService.getLmsUserFeedback(this.slug).subscribe((data: PlatformQuestionFeedback) => {
      this.feedback = data;
    });
  }

  onClick(): void {
    this.activeModal.close('cancel');
  }

  feedbackSubmit() {
    if (!this.feedback.comments && this.feedback.rating == 0){
      this.activeModal.close('cancel');
      return;
    }

    let feedbackAction$: Observable<PlatformQuestionFeedback>;
    feedbackAction$ = this.feedbackService.putLmsUserFeedback(this.slug, this.feedback);
    feedbackAction$.subscribe();
    this.activeModal.close('submit');
  }
}
