import { ActivatedRoute } from '@angular/router';
import { QuestionPreviewerService } from '../../services/question-previewer/question-previewer.service';
import { Question } from '../../models/question';
import { SubmittedAnswerAnswer, SubmittedAnswerResult } from '../../models/submitted-answer-result';
import { concatMap, map, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-platform-question-previewer',
  templateUrl: './platform-question-previewer.component.html',
  styleUrls: ['./platform-question-previewer.component.scss']
})
export class PlatformQuestionPreviewerComponent implements OnInit {
  question: Question;
  courseId: number;
  quizId: number;
  questionId: number;
  answerResult: SubmittedAnswerResult;
  question$ = new BehaviorSubject<Question>(null);
  answerResult$ = new BehaviorSubject<SubmittedAnswerResult>(null);
  answerResultRetrieved$ = new BehaviorSubject(null);
  canvasAccountId: number;

  constructor(
    private route: ActivatedRoute,
    private questionPreviewerService: QuestionPreviewerService,
  ) {}

  ngOnInit(): void {
    const routeParams$ = this.route.params;
    routeParams$.pipe(
      map(params => {
        this.courseId = params.courseId;
        this.quizId = params.quizId;
        this.questionId = params.questionId;
      }),
      concatMap(() => this.questionPreviewerService.getQuestionForPreview(this.courseId, this.quizId, this.questionId)),
      tap(data => {
          this.question = data;
          if (data.accountId != null) {
            this.canvasAccountId = data.accountId;
          }
          this.question$.next(data);
          this.setCorrectAnswer();
        }
      ),
    ).subscribe();
  }

  setCorrectAnswer(): void {
    const correct = this.question.answers.find(answer => answer.weight === 100);
    if (correct) {
      try {
        const submittedAnswer = new SubmittedAnswerAnswer();
        submittedAnswer.answer = correct.id.toString();
        this.answerResult = new SubmittedAnswerResult();
        this.answerResult.answer = submittedAnswer;
        this.answerResult.correctComments = correct.commentsHtml;
        this.answerResult.isCorrect = true;
        this.answerResult.allowReattempt = false;
        this.answerResult$.next(this.answerResult);
        this.answerResultRetrieved$.next(true);
      }
      catch(e) {
        console.log(e);
      }
    }

    this.question$.value.canAnswer = false;
  }
  handleSubmit() {}
}
