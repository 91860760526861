import { Component, OnInit } from '@angular/core';
import {FileUploadQuestionComponent} from "../../../question-types/file-upload-question/file-upload-question.component";

@Component({
  selector: 'app-file-upload-question-previewer',
  templateUrl: './file-upload-question.component.html',
  styleUrls: ['./file-upload-question.component.scss']
})
export class FileUploadQuestionPreviewerComponent extends FileUploadQuestionComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}
