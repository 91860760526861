// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'development',
  apiBase: 'https://dev-api.quiztimehealth.com',
  apiUrl: 'https://dev-api.quiztimehealth.com/api',
  catalogUrl: 'https://dev.quiztimehealth.com/',
  learnerPortalUrl: 'https://dev-quiztime.pantheonsite.io',
  froalaApiKey: '',
  applicationInsights: {
    enable: true,
    instrumentationKey: '039fd018-1880-4939-95a6-5674bc2f29cf'
  },
  auth0Config: {
    clientID: '3gsJa91OumuvRrJ7K6dur2Q1ZpoqqF6E',
    domain: 'tnhqt-learnerportal.auth0.com',
    audience: 'qt-core-api'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
