import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {QuestionStatistics} from 'src/app/models/question-statistics';
import {environment} from '../../../environments/environment';

@Injectable()
export class QuestionStatisticsService {

  API_URL = environment.apiBase;
  VS_URL = 'https://localhost:44398/api';

  constructor(private http: HttpClient) {

  }

  getQuestionStatistics(href: string): Observable<QuestionStatistics> {
    return this.http
      .get<QuestionStatistics>(this.API_URL + href);
  }
}
