<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>
<form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <label for="questionAnswer">Fill in the blank:</label>
    <input
      type="text"
      [(ngModel)]="questionAnswer"
      required
      id="questionAnswer"
      name="questionAnswer"
      [disabled]="answerResult$.value != null || !question$.value.canAnswer"
    >
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question,
      questionLoaded: true
    }"
  ></ng-template>
  <ng-container *ngIf="canShowCorrectAnswer()">
    <div class="alert alert-success correct-answer">The correct answer is: {{ correctAnswerText }}</div>
  </ng-container>
  <ng-container *ngIf="answerResult$.value != null">
    <div *ngIf="answerHasComments()">
      <div *ngIf="answerResult$.value.answerDetails[0].commentsHtml != '' && answerResult$.value.answerDetails[0].commentsHtml !== null">
        <div
          class="answer-comments alert alert-info"
          [innerHTML]="answerComments()">
        </div>
    </div>
  </div>
  </ng-container>
  <hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !submissionForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
