import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question} from '../../../models/question';

@Component({
  selector: 'app-question-acknowledge',
  templateUrl: './question-acknowledge.component.html',
  styleUrls: ['./question-acknowledge.component.scss']
})
export class QuestionAcknowledgeComponent {
  @Input()
  question: Question;
  @Output()
  acknowledgementSubmit: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  shouldEnableAcknowledgement() {
    return this.question.questionType !== 'Announcement' &&
      (this.question.canAcknowledge() || this.question.isImmediateRetakeAvailable());
  }

  ackowledgementClicked() {
    this.acknowledgementSubmit.emit();
  }

}
