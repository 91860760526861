import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit} from '@angular/core';
import {LearnerAnswerResultInterface} from '../../../models/learner-answer-result.interface';
import {Question} from '../../../models/question';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import {DomSanitizer} from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() question: Question;
  @Input() answerResult: LearnerAnswerResultInterface;
  @Input() isSubmitting: boolean;
  @Output() responseFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  announcementValue = 'Confirm';
  faCheck = faCheck;
  faBullhorn = faBullhorn;
  questionExplanation;
  questionLeadIn: string;
  questionChoices = [];

  constructor(private domSanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    $('.question-presenter img').wrap('<div class="pinchzoom-wrapper" />');
    $('.pinchzoom-wrapper img').pinchzoomer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question !== undefined && changes.question.previousValue !== undefined) {
      this.setQuestionExplanation();
    }
  }

  ngOnInit(): void {
    // Use the unsanitized values for these fields.  Do it in OnInit because ChangeDetection will reload iframes if called from HTML
    this.questionChoices = this.question.choices.map((choice) => this.useTrustedInput(choice));
    this.questionLeadIn = this.useTrustedInput(this.question.leadIn);
    this.setQuestionExplanation();
  }

  setQuestionExplanation(): void {
    this.questionExplanation = this.question.explanation
      ? this.useTrustedInput(this.question.explanation)
      : '';
  }

  useTrustedInput(input: string): string {
    return this.domSanitizer.bypassSecurityTrustHtml(input) as string;
  }

  handleSubmit(value, isValid: boolean) {
    if (isValid) {
      this.responseFormSubmit.emit(value);
    }
  }

  showQuestionSubmissionNotification() {
    return ['OpenText', 'Announcement'].indexOf(this.question.getType()) === -1;
  }

  validateReference(reference: string) {
    if (reference.indexOf('http://') !== -1 || reference.indexOf('https://') !== -1) {
      return reference;
    } else {
      return 'https://' + reference;
    }
  }
}
