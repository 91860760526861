import { ApiBase } from './api-base.interface';
import { NumberSymbol } from '@angular/common';

export class QuestionStatistics extends ApiBase {
total: number;
errors: number;
answer: string;
opened: number;
completed: number;
correct: number;
incorrect: number;
correctAnswerKey: number;
attemptOneCorrect: number;
attemptTwoCorrect: number;
correctAnswerValue: string;
percentageCorrect: number;
ratingAverage: number;
questionType: string;
answerStatistics: any;
}
