<form [formGroup]="multipleAnswersForm">
  <fieldset [disabled]="true">
    <legend class="visually-hidden">Choose answer:</legend>
    <div>
      <ng-container *ngFor="let order of answersFormArray.controls; let i = index">
        <label formArrayName="answers" >
          <input type="checkbox" [formControlName]="i">
          <span *ngIf="!this.question.answers[i].html">
            {{this.question.answers[i].text}}
          </span>
          <span *ngIf="this.question.answers[i].html !== null"
            [innerHTML]="removeFirstParagraphTag(this.question.answers[i].html) | safeHtml">
          </span>
          <div
            *ngIf="this.question.answers[i].commentsHtml != '' &&
            this.question.answers[i].weight == 100 &&
            this.question.answers[i].commentsHtml != null"
            class="answer-comments alert alert-success"
            [innerHtml]="this.question.answers[i].commentsHtml | safeHtml">
          </div>
          <div
            *ngIf="this.question.answers[i].commentsHtml != '' &&
            this.question.answers[i].weight != 100 &&
            this.question.answers[i].commentsHtml != null"
            class="answer-comments alert alert-danger"
            [innerHtml]="this.question.answers[i].commentsHtml | safeHtml">
          </div>
        </label>
        <ng-container *ngIf="answerResult != null">
          <div *ngIf="answerHasComments(this.question.answers[i].id)">
            <div
              class="answer-comments alert alert-info"
              [innerHTML]="answerComments(this.question.answers[i].id)">
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </fieldset>
</form>
