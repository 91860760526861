import {Component, OnInit} from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {StringQuestionAnswer} from '../../../models/answered-question';

@Component({
  selector: 'app-numeric-question',
  templateUrl: './numeric-question.component.html',
  styleUrls: ['./numeric-question.component.scss']
})
export class NumericQuestionComponent extends BaseQuestionComponent implements OnInit {
  questionAnswer: any;

  ngOnInit(): void {
    super.ngOnInit();
  }

  setAnswer() {
    if (this.answerResult != null) {
      this.questionAnswer = this.answerResult.answer;
    }
  }

  handleSubmit(value, isValid) {
    this.toggleQuestionSubmitting();
    const answer = new StringQuestionAnswer();
    answer.value = value.questionAnswer;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }
}
