import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {UserInterface} from '../../models/users/user.interface';
import {UserEmailPreferencesInterface} from '../../models/users/user-email-preferences.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  API_URL = environment.apiBase;

  constructor(private http: HttpClient) {}

  lookupUserByEmail(email: string): Observable<UserInterface> {
    email = encodeURIComponent(email);
    return this.http.get<UserInterface>(`${this.API_URL}/api/v2/user/lookup?email=${email}`)
      .pipe(
        // tap(data => console.log(data)),
        catchError(err => this.handleError(err))
      );
  }

  getUsersEmailPreferences(email: string): Observable<UserEmailPreferencesInterface> {
    return this.http.get<UserEmailPreferencesInterface>(`${this.API_URL}/api/v2/emailPreferences?email=${encodeURIComponent(email)}`)
      .pipe(
        // tap(data => console.log(data)),
        catchError(err => this.handleError(err))
      );
  }

  putUsersEmailPreferences(email: string, preferences: UserEmailPreferencesInterface) {
    return this.http.put(`${this.API_URL}/api/v2/emailPreferences?email=${encodeURIComponent(email)}`, preferences)
      .pipe(
        // tap(data => console.log(data)),
        catchError(err => this.handleError(err))
      );
  }

  private handleError(err): Observable<any> {
    if (!environment.production) {
      console.log(err);
    }
    return of([]);
  }
}
