import { Component, OnInit } from '@angular/core';
import { ReplacementFieldsBaseQuestionComponent } from '../../../question-types/base-question/base-question.component';
import { MultiTextPipe } from 'src/app/shared/pipes/multi-text.pipe';

@Component({
  selector: 'app-multiple-blanks-question-previewer',
  templateUrl: './multiple-blanks-question.component.html',
  styleUrls: ['./multiple-blanks-question.component.scss']
})
export class MultipleBlanksQuestionPreviewerComponent extends ReplacementFieldsBaseQuestionComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

  getElements(): void {}

  formListener(): void {}

  handleSubmit() {}
}
