import {Component, OnInit} from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {IntQuestionAnswer} from '../../../models/answered-question';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent extends BaseQuestionComponent implements OnInit {
  error = false;
  questionAnswer: any;

  // TODO send event to parent
  handleSubmit(value, isValid): void {
    this.toggleQuestionSubmitting();
    if (isValid) {
      const answer = new IntQuestionAnswer();
      // For multiple choice, each question answer will have the same value
      answer.value = this.questionAnswer;
      setTimeout(() => {
        this.answerSubmitted.emit(answer);
      }, 1);
    }
  }

  setAnswer() {
    if (this.answerResult != null) {
      this.questionAnswer = this.answerResult.answer;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
