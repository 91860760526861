import {Component, OnInit} from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {StringQuestionAnswer} from '../../../models/answered-question';
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js"
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/url.min.js";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-essay-question',
  templateUrl: './essay-question.component.html',
  styleUrls: ['./essay-question.component.scss']
})
export class EssayQuestionComponent extends BaseQuestionComponent implements OnInit {
  questionAnswer: any;
  editor: any;
  isEmpty = true;
  options: Object = {
    placeholderText: "Your answer",
    attribution: false,
    key: "CTD5xE3A2D2C2A4B5wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4C3C6E2A2F4E1I1==",
    pluginsEnabled: ['fontSize', 'paragraphFormat', 'colors', 'link', 'align', 'table', 'lists'],
    fontSizeSelection: true,
    fontSize: ['8', '10', '12', '14', '18', '30', '60', '96'],
    toolbarButtons: {
      'moreText': {
        'buttons': ['fontSize', 'paragraphFormat', '|', 'bold', 'italic', 'underline', 'textColor', 'backgroundColor', 'subscript', 'superscript'],
        'buttonsVisible': 7
      },
      'moreLink': {
        'buttons': ['|', 'insertLink', '|'],
        'buttonsVisible': 10
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignJustify', 'alignCenter', 'alignRight'],
        'buttonsVisible': 1
      },
      'moreRich': {
        'buttons': ['|', 'insertTable', 'formatOL', 'formatUL', '|']
      }
    },
    toolbarButtonsXS: {
      'moreText': {
        'buttons': ['fontSize', 'paragraphFormat', '|', 'bold', 'italic', 'underline', 'textColor', 'backgroundColor', 'subscript', 'superscript', 'alignLeft', 'alignJustify', 'alignCenter', 'alignRight'],
        'buttonsVisible': 2
      },
      'moreLink': {
        'buttons': ['|', 'insertLink', '|'],
        'buttonsVisible': 10
      },
    },
    events : {
      'input': i => this.isEmpty = this.editor.core.isEmpty(),
      'initialized': (e) => {
        this.editor = e.getEditor();
        this.answerResultRetrieved$.subscribe(d => {
          if (d === true && this.answerResult$.value == null) {
            this.editor.edit.on();
          }
          if (d === false) {
            this.editor.edit.off();
          }
        });
      }
    }
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  setAnswer() {
    if (this.answerResult != null) {
      this.questionAnswer = this.answerResult.answer;
    }
  }

  handleSubmit(value, isValid) {
    this.toggleQuestionSubmitting();
    if (isValid) {
      const answer = new StringQuestionAnswer();
      answer.value = this.questionAnswer;
      setTimeout(() => {
        this.answerSubmitted.emit(answer);
      }, 1);
    }
  }
}
