/**
 * TODO
 * 1. Allow passing format
 * 2. Allow passing desired timezone
 */
import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault('UTC');
@Pipe({
   name: 'momentTz'
})
export class MomentTzPipe implements PipeTransform {
  transform(value: string): string {
    if ((typeof value) !== 'string') {
      return value;
    }

    return moment(value).tz('America/Chicago').format('MM/DD/YY h:mm a');
  }
}
