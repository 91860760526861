<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>
<form (ngSubmit)="handleSubmit()" #submissionForm="ngForm" novalidate>
  <hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResultRetrieved$.value === false || answerResult$.value != null || !submissionForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Mark as Complete</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
