<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>
<form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
    <label for="questionAnswer" class="visually-hidden">Your file:</label>
    <input
      [(ngModel)]="file"
      name="questionAnswer"
      id="questionAnswer"
      type="file"
      class="file-input"
      required
      (change)="onFileSelected($event)" #fileUpload>
    <div class="file-upload" *ngIf="fileSubmissionId < 1">
        <span *ngIf="file">{{ file.name }}</span>
        <span *ngIf="!file">No file uploaded yet.</span>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="fileUpload.click()">Select file</button>
    </div>
  <hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !submissionForm.valid || file === undefined"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
