import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserEmailPreferencesInterface} from '../../models/users/user-email-preferences.interface';

@Component({
  selector: 'app-email-preferences-form',
  templateUrl: './email-preferences-form.component.html',
  styleUrls: ['./email-preferences-form.component.scss']
})
export class EmailPreferencesFormComponent implements OnInit {
  @Input() emailPreferences: UserEmailPreferencesInterface;
  @Input() email: string;
  @Output() preferencesUpdated: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {
    if (this.emailPreferences.suppressions.global) {
      this.emailPreferences.suppressions.suppressionGroups.map(e => e.suppressed = true);
    }
  }

  emailPreferencesSubmit(): void {
    this.preferencesUpdated.emit(this.emailPreferences);
  }

  monitorGlobal(): void {
    this.emailPreferences.suppressions.suppressionGroups.forEach((group, index, complete) => {
      complete[index].suppressed = this.emailPreferences.suppressions.global;
    });
  }

  ensureGlobal(val): void {
    if (val === 'on' && this.emailPreferences.suppressions.global) {
      this.emailPreferences.suppressions.global = false;
    }
  }
}
