export class SubmittedAnswerResult {
  allowReattempt: boolean;
  incorrectComments: string;
  correctComments: string;
  neutralComments: string;
  questionCorrectComments: string;
  questionIncorrectComments: string;
  questionNeutralComments: string;
  isCorrect: boolean;
  submissionAttempt: number;
  remainingAttempts: number;
  answer: any;
  answerDetails: AnswerDetail[];
}

export class AnswerDetail {
  id: number;
  commentsHtml: string;
}

export class SubmittedAnswerAnswer {
  answer: string;
}
