<ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
  <fieldset>
    <legend class="visually-hidden">Choose:</legend>
    <div
      class="form-check"
      *ngFor="let answer of useQuestion.answers; index as id">
      <input
        class="form-check-input  {{ (answerResult$ | async).answer.answer == answer.id ? 'is-correct-answer' : '' }}"
        type="radio"
        name="questionAnswer"
        id="choice-{{ id }}"
        [value]="answer.id"
        [disabled]="true"
        [checked]="(answerResult$ | async).answer.answer == answer.id"
      />
      <label
        class="form-check-label"
        for="choice-{{ id }}"
        [innerHTML]="getAnswerText(answer) | safeHtml">
      </label>
      <div
        *ngIf="answerResult$.value.answer.answer === answer.id
          && ((answer.comments != null && answer.comments != '') || (answer.commentsHtml != null && answer.commentsHtml != ''))"
        class="answer-comments alert alert-success"
        [innerHTML]="answer.commentsHtml != '' && answer.commentsHtml != null ? answer.commentsHtml : answer.comments | safeHtml">
      </div>
      <div
        class="answer-comments alert alert-danger"
        *ngIf="answerResult$.value.answer.answer !== answer.id
          && ((answer.comments !== null && answer.comments !== '') || (answer.commentsHtml !== null && answer.commentsHtml !== ''))"
        [innerHTML]="answer.commentsHtml != '' && answer.commentsHtml != null ? answer.commentsHtml : answer.comments | safeHtml">
      </div>
    </div>
  </fieldset>
</ng-template>
<ng-template
  [ngTemplateOutlet]="questionFormTemplate"
  [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
></ng-template>
