import { ActivatedRoute } from '@angular/router';
import { QuestionPreviewerService } from '../../../../services/question-previewer/question-previewer.service';
import { Question } from '../../../../models/question';
import { SubmittedAnswerAnswer, SubmittedAnswerResult } from '../../../../models/submitted-answer-result';
import { concatMap, map, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { BaseQuestionComponent } from '../../../question-types/base-question/base-question.component';

@Component({
  selector: 'app-text-only-question-previewer',
  templateUrl: './text-only-question.component.html',
  styleUrls: ['./text-only-question.component.scss']
})
export class TextOnlyQuestionPreviewerComponent extends BaseQuestionComponent {
  question: Question;
  courseId: number;
  quizId: number;
  questionId: number;
  answerResult: SubmittedAnswerResult;

  handleSubmit() {}
}
