import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-list-previewer',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListPreviewerComponent implements OnInit {
  ngOnInit() {}
}
