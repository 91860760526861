import { ApplicationInsightsLoggingService } from './application-insights-logging.service';
import { environment } from 'src/environments/environment';
import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService extends ErrorHandler {

    constructor(private appInsightsLogging: ApplicationInsightsLoggingService) {
        super();
    }

    handleError(error: any): void {
      if (environment.applicationInsights.enable) {
        this.appInsightsLogging.logException(error);
      }
    }

    logException(error: Error) {
      if (environment.applicationInsights.enable) {
          this.appInsightsLogging.logException(error);
      }
    }
}
