<ng-container>
  <form #multiForm>
    <div *ngIf="!showFullQuestion()" [innerHTML]="questionDisplay.questionText | safeHtml"></div>
    <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
      <div *ngIf="questionLoaded" id="form-replacement-field" #formReplacementField [innerHTML]="formString | safeHtml"></div>
    </ng-template>
    <ng-template
      *ngIf="!showFullQuestion()"
      [ngTemplateOutlet]="questionFormTemplate"
      [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
    ></ng-template>
  </form>
</ng-container>
