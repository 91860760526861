import {Component, Input} from '@angular/core';
import {LearnerAnswerResultInterface} from '../../../models/learner-answer-result.interface';

@Component({
  selector: 'app-learner-answer-result',
  templateUrl: './learner-answer-result.html'
})

export class LearnerAnswerResultComponent {
  @Input() answerResult: LearnerAnswerResultInterface;
}
