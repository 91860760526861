/**
 * OAuth doesn't accurately describe what this does, because it's more of a pass through to the OAuth service within 2.0
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OauthService {
  API_URL = environment.apiBase;
  constructor(private http: HttpClient) {}

  confirmLearnerAuthenticated(slug: string, targetUrl: string): Observable<any> {
    return this.http.get(`${this.API_URL}/api/v2/obo/learnerConfirmation?slug=${slug}&targetUrl=${targetUrl}`)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
