export class AnalyticEvents {
  static readonly QuestionViewed = 'questionViewed';
  static readonly QuestionAnswered = 'questionAnswered';
  static readonly ReattemptInitiated = 'reattemptInitiated';
}

export class QuestionViewedEvent {
  public slug: string;
  public remoteQuestionId: number;
  public attemptNumber: number;

  public constructor(slug: string, remoteQuestionId: number, attemptNumber: number) {
    this.slug = slug;
    this.remoteQuestionId = remoteQuestionId;
    this.attemptNumber = attemptNumber;

    return this;
  }
}

export class QuestionAnsweredEvent {
  public slug: string;
  public answer: string;
  public remoteQuestionId: number;
  public attemptNumber: number;

  public constructor(slug: string, answer: string, remoteQuestionId: number, attemptNumber: number) {
    this.slug = slug;
    this.answer = answer;
    this.remoteQuestionId = remoteQuestionId;
    this.attemptNumber = attemptNumber;

    return this;
  }
}
