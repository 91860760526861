<form #emailPreferencesLookupForm="ngForm" (ngSubmit)="emailLookupSubmit(emailPreferencesLookupForm.value, emailPreferencesLookupForm.valid)">
  <div class="form-group">
    <label for="emailLookup" class="visually-hidden">Email address</label>
    <input
      type="email"
      [(ngModel)]="email"
      [email]="true"
      name="email"
      class="form-control"
      id="emailLookup"
      aria-describedby="emailHelp"
      placeholder="Enter email"
      required
    >
    <small id="emailHelp" class="form-text text-muted">We'll lookup your email preferences so you can manage them.</small>
  </div>
  <button type="submit" class="btn btn-primary" [disabled]="!emailPreferencesLookupForm.valid">Lookup Email</button>
</form>
