import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, tap } from "rxjs/operators";
import { QuestionService } from '../../../services/questions/question.service';
import { LinkInterface } from '../../../models/link.interface';
import { HateOasService } from '../../../shared/utilities/hateoas';
import { Attempt } from '../../../models/attempt.interface';
import { Question } from '../../../models/question';
import {BehaviorSubject, of} from 'rxjs';
import { QuestionAnswerSubmission } from '../../../models/question-answer-submission.interface';
import { GlobalLoaderService } from '../../../services/global-loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsightsLoggingService } from 'src/app/services/application-insights-logging.service';

@Component({
  selector: 'app-question-presenter',
  templateUrl: 'question-presenter.component.html',
  styleUrls: ['./question-presenter.component.scss']
})
export class QuestionPresenterComponent implements OnInit {
  attempt: Attempt;
  question: Question;
  answerResult: QuestionAnswerSubmission;
  submittedAnswer: string;
  httpError: HttpErrorResponse = undefined;
  isSubmitting$ = new BehaviorSubject<boolean>(false);
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questionService: QuestionService,
    private globalLoaderService: GlobalLoaderService,
    private hateoas: HateOasService,
    private logging: ApplicationInsightsLoggingService
  ) {}

  ngOnInit(): void {
    const routeParams$ = this.route.params;
    routeParams$.pipe(
      // Get RequestID from params.
      map(params => {
        return params.requestId;
      }),
      // Get and set the attempt
      mergeMap((requestId) => this.questionService.getAttemptRequest(requestId)),
      tap(attempt => this.attempt = attempt),
      // With the attempt, get question
      map((attempt: Attempt) => {
        return this.loadQuestionIfAvailable(attempt.links);
      }),
      mergeMap((question$) => {
        return question$.pipe(
          map(question => {
            this.setQuestionObject(question);
            return question;
          })
        );
      }),
      map(question => {
        this.submissionsWorker(question);
      })
    ).subscribe(() => this.globalLoaderService.hide());
  }

  setQuestionObject(question: Question) {
    const newQuestion = new Question(this.hateoas);
    Object.assign(newQuestion, question);
    this.question = newQuestion;
    this.question.attemptId = this.attempt.id;
  }

  handleQuestionResponse(event): void {
    this.isSubmitting$.next(true);
    // TODO interface for event type.
    this.submittedAnswer = event.questionAnswer;
    const postSubmissionLink = this.hateoas.getLinkInterfaceForRel(this.question.links, 'post_submission');
    const postSubmission$ = this.questionService.post(postSubmissionLink.href, {providedAnswer: event.questionAnswer});
    postSubmission$.pipe(
      map((learnerAnswerResult: QuestionAnswerSubmission) => {
        this.answerResult = learnerAnswerResult;
        return learnerAnswerResult;
      }),
      map((learnerAnswerResult: QuestionAnswerSubmission) => {
        return this.loadQuestionIfAvailable(learnerAnswerResult.links);
      }),
      mergeMap((question$) => {
        return question$.pipe(
          map(question => {
            this.setQuestionObject(question);
            return question;
          })
        );
      }),
      map(question => {
        this.submissionsWorker(question);
      })
    ).subscribe(() => this.isSubmitting$.next(false));
  }

  handleFeedbackSubmissionEvent(event): void {
    window.location.reload();
  }

  acknowledge() {
    this.globalLoaderService.show();
    const acknowledgeLink = this.hateoas.getLinkInterfaceForRel(this.question.links, 'Acknowledge_attempt');
    const immediateReattempt = this.hateoas.getLinkInterfaceForRel(this.question.links, 'Immediate_Reattempt');
    if (acknowledgeLink == undefined && immediateReattempt != undefined) {
      return of(this.router.navigate(['quiz', this.question.nextAttemptId])).subscribe();
    }
    const acknowledge$ = this.questionService.post(acknowledgeLink.href, {acknowledge: true});
    acknowledge$.pipe(
      map((question: Question) => {
        this.setQuestionObject(question);
        return question;
      }),
      map(question => {
        this.submissionsWorker(question);
      }),
      map(() => {
        if (immediateReattempt !== undefined && this.question.isImmediateRetakeAvailable()) {
          return this.questionService.get(immediateReattempt.href);
        }
        return of({});
      }),
      map(() => {
        if (this.question.nextAttemptId !== undefined && this.question.attemptId !== this.question.nextAttemptId) {
          // We have a new question coming;
          const nextAttemptId = this.question.nextAttemptId;
          this.question = null;
          return of(this.router.navigate(['quiz', nextAttemptId]));
        }
        return of({});
      })
    ).subscribe(() => this.globalLoaderService.hide());
  }

  submissionsWorker(question: Question) {
    const submissions$ = this.loadSubmissionsIfAvailable(question.links);
    if (submissions$ !== undefined) {
      submissions$.subscribe(submissions => this.question.setSubmissions(submissions));
    }
  }

  loadQuestionIfAvailable(links: LinkInterface[]) {
    const getQuestionLink = this.hateoas.getLinkInterfaceForRel(links, 'Get_question');
    if (getQuestionLink) {
      return this.questionService.get(getQuestionLink.href);
    }
  }

  loadSubmissionsIfAvailable(links: LinkInterface[]) {
    const getSubmissionsLink = this.hateoas.getLinkInterfaceForRel(links, 'Get_submissions');
    if (getSubmissionsLink) {
      return this.questionService.get(getSubmissionsLink.href);
    }
  }

  shouldShowCourseComplete() {
    return this.answerResult !== undefined
      && this.answerResult.remainingQuestions < 1
      && this.question.enrollee.profileComplete === false;
  }

  shouldShowFooter() {
    return this.question.isFeedbackAvailable() || this.question.isStatisticsAvailable() ||
      (!this.question.canSubmit() && !this.question.canAcknowledge());
  }
}
