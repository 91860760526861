<form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <div
      *ngIf="!questionLoaded"
      class="question__lead-in"
      [innerHTML]="questionDisplay.questionText | safeHtml">
    </div>
    <div
      *ngIf="questionLoaded"
      class="question__lead-in"
      [innerHTML]="question$.value.questionText | safeHtml">
    </div>
    <label for="questionAnswer">Answer: </label>
    <input style="margin-left:5px"
      type="number"
      [(ngModel)]="questionAnswer"
      required
      id="questionAnswer"
      name="questionAnswer"
      [disabled]="this.answerResult$.value != null || !questionLoaded"
    >
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
  ></ng-template>
  <ng-container *ngIf="canShowCorrectAnswer()">
    <div class="alert alert-success correct-answer">
      The correct answer is: {{question$.value.correctAnswer.answer}}
    </div>
  </ng-container>
  <hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !submissionForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
