import {Pipe, PipeTransform} from '@angular/core';
import {ReplacementVariable} from '../../platform-question/question-types/base-question/base-question.component';
import {UntypedFormGroup} from '@angular/forms';
import {SubmittedAnswerResult} from '../../models/submitted-answer-result';
import { Question } from 'src/app/models/question';

@Pipe({
  name: 'dropdown'
})
export class MultiDropdownsPipe implements PipeTransform {
  transform(value: string[], replacementVars: ReplacementVariable[], form: UntypedFormGroup, answerResult: SubmittedAnswerResult): any {
    let compiledString = '';
    value.forEach(v => {
      if (replacementVars.find(r => r.withBrackets === v) === undefined) {
        compiledString += v;
      } else {
        const rv = replacementVars.find(rvf => rvf.withBrackets === v);
        const disabled = answerResult != null ? 'disabled=disabled' : '';
        let select = `<label for="field-${rv.asString}" class="visually-hidden">${rv.asString}</label><select id="field-${rv.asString}" name="${rv.asString}" ${disabled} >`
          + `<option value=""> - Select - </option>`;
        rv.answerOptions.forEach(ao => {
          const isSelected = answerResult != null && answerResult.answer[ao.blankId] === ao.id.toString() ? 'selected=selected' : '';
          select += `<option value="${ao.id}" ${isSelected}">${ao.text}</option>`;
        });
        select += '</select>';

        compiledString += select;
      }
    });
    return compiledString ;
  }

  transformForPreviewWithCorrectValues(value: string[], replacementVars: ReplacementVariable[], form: UntypedFormGroup, question: Question, isCorrectAnswerDisplay = false): any {
    let compiledString = '';
    value.forEach(v => {
      if (replacementVars.find(r => r.withBrackets === v) === undefined) {
        compiledString += v;
      } else {
        const rv = replacementVars.find(rvf => rvf.withBrackets === v);
        const selectId = !isCorrectAnswerDisplay ? `field-${rv.asString}` : `field-${rv.asString}--as-correct`;
        const selectName = !isCorrectAnswerDisplay ? `${rv.asString}` : `${rv.asString}--as-correct`;
        const disabledString = question.answerResult != null || isCorrectAnswerDisplay ? 'disabled=disabled' : '';
        let select = `<label for="${selectId}" class="visually-hidden">${rv.asString}</label><select id="${selectId}" name="${selectName}" ${disabledString}>`
          + `<option value=""> - Select - </option>`;
        rv.answerOptions.forEach(ao => {
          const answer = question.correctAnswers.find(a => a.blankId === ao.blankId);
          const isSelected = answer.id === ao.id ? 'selected=selected' : '';
          select += `<option value="${ao.id}" ${isSelected}">${ao.text}</option>`;
        });
        select += '</select>';

        compiledString += select;
      }
    });
    return compiledString ;
  }
}
