import { SubmittedAnswerAnswer, SubmittedAnswerResult } from '../../../../models/submitted-answer-result';
import { Component, OnInit } from '@angular/core';
import { BaseQuestionComponent } from '../../../question-types/base-question/base-question.component';

@Component({
  selector: 'app-multiple-choice-question-previewer',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionPreviewerComponent extends BaseQuestionComponent {
    handleSubmit() {}
  }
