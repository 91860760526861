
import { Component } from '@angular/core';

import { BaseQuestionComponent } from 'src/app/platform-question/question-types/base-question/base-question.component';
@Component({
  selector: 'app-short-answer-question-previewer',
  templateUrl: './short-answer-question.component.html',
  styleUrls: ['./short-answer-question.component.scss']
})
export class ShortAnswerQuestionPreviewerComponent extends BaseQuestionComponent {
  handleSubmit() {}
}
