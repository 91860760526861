import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment} from '../../../environments/environment';
import { Question } from '../../models/question';

@Injectable()
export class QuestionPreviewerService {

  API_URL = environment.apiBase;

  constructor(private http: HttpClient) {}

  getQuestionForLegacyPreview(questionId: string): Observable<any> {
    return this.http
      .get(this.API_URL + `/api/questions/preview/${questionId}`)
      .pipe(
        map((response: Response) => response)
      );
  }

  getQuestionForPreview(courseId: number, quizId: number, questionId: number): Observable<any> {
    return this.http
      .get(this.API_URL + `/api/v2/lms/course/${courseId}/quizzes/${quizId}/questions/${questionId}`)
      .pipe(
        map((response: Response) => response)
      );
  }
}
