import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedbacks/feedback.service';
import { Feedback } from 'src/app/models/feedback';
import { Question } from 'src/app/models/question';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HateOasService } from '../../../shared/utilities/hateoas';
import { tap, finalize } from "rxjs/operators";
import { Observable } from 'rxjs';
@Component({
  selector: 'app-question-feedback',
  templateUrl: './question-feedback.component.html',
  styleUrls: ['./question-feedback.component.scss']
})

export class QuestionFeedbackComponent implements OnInit {

  @Input() question: Question;
  @Output() feedbackFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

  feedback: Feedback = new Feedback();

  constructor(
    private feedbackService: FeedbackService,
    private hateoas: HateOasService,
    public activeModal: NgbActiveModal

    ) { }

  ngOnInit(): void {
    const getLink = this.hateoas.getLinkInterfaceForRel(this.question.links, 'Get_Feedbacks');

    this.feedbackService.getUserFeedback(getLink.href).subscribe((data: Feedback) => {
    // If there's no record, use defaultly constructed model values in feedback.ts
      if (data != null) {
        this.feedback = data;
      }
    });
  }

  onClick(): void {
    this.activeModal.close('closed');
  }

  feedbackSubmit(form) {
    const providedFeedback = {
      // @TODO Rating is passed as a string unless explicitly cast as a number. Not sure why! Don't think we want this
      rating: Number(form.value.star),
      comments: form.value.comments
    };
    let feedbackAction$: Observable<Feedback>;

    if (this.question.links.some(links => links.rel === "Post_Feedback")) {
      const postLink = this.hateoas.getLinkInterfaceForRel(this.question.links, 'Post_Feedback');
      feedbackAction$ = this.feedbackService.postUserFeedback(postLink.href, this.feedback)
    }
    if (this.question.links.some(links => links.rel === 'Patch_Feedback')) {
      const patchLink = this.hateoas.getLinkInterfaceForRel(this.question.links, 'Patch_Feedback');
      feedbackAction$ = this.feedbackService.patchUserFeedback(patchLink.href, this.feedback);
    }

    feedbackAction$.pipe(
      finalize( () => {
        this.feedbackFormSubmitEvent.emit();
      })
    )
    .subscribe();

    this.activeModal.close('closed');
  }
}
