import { UrlReferenceInterface } from './urlReference.interface';
import { ApiBase } from './api-base.interface';
import { QuestionAnswerSubmission } from './question-answer-submission.interface';
import { EnrolleeInterface } from './enrollee.interface';
import { HateOasService } from '../shared/utilities/hateoas';
import {Submission} from './submission';
import {SubmittedAnswerResult} from './submitted-answer-result';

export class Answer {
  id: number;
  text: string;
  weight: number;
  comments: string;
  commentsHtml: string;
  blankId: string;
  variables: Variable[];
  answer: number;
  answerTolerance: number;
  FormulaDecimalPlaces: number;
  matchId: number;
  exact: number;
  margin: number;
  precision: number;
  numericalAnswerType: string;
  approximate: number;
  start: number;
  end: number;
  html: string;
}

export class Variable {
  name: string;
  value: number;
  min: number;
  max: number;
  scale: number;
}

export class Match {
  text: string;
  matchId: number;
}

export class Question extends ApiBase {
  correctAnswer: any;
  correctAnswers: any[];
  lastSubmissionId: string;
  remoteQuestionId: number;
  numberAttempts: number;
  canAnswer: boolean;
  id: string;
  stem: string;
  leadIn: string;
  expirationDate: string;
  immediateRetakeAvailable: boolean;
  canAttempt: boolean;
  correctComments: string;
  correctCommentsHtml: string;
  neutralComments: string;
  neutralCommentsHtml: string;
  incorrectComments: string;
  incorrectCommentsHtml: string;
  timeLimit: number;
  requiresAnswer: boolean;
  contributorName?: string;
  willAcceptAnswer: boolean;
  isExpired: boolean;
  choices?: [];
  value?: string;
  explanation: string;
  explanationFile: string;
  references: UrlReferenceInterface[];
  statisticsAvailable: boolean;
  feedbackAvailable: boolean;
  submissionAttempts: number;
  submissions: QuestionAnswerSubmission[];
  lastSubmissionValue: string = undefined;
  thisSubmissionValue: string = undefined;
  lastSubmission: QuestionAnswerSubmission;
  thisSubmission: QuestionAnswerSubmission;
  attemptId: string;
  nextAttemptId: string = undefined;
  enrollee: EnrolleeInterface;
  remainingAttempts: number;
  questionName: string;
  questionText: string;
  questionType: string;
  platformQuizType: string;
  answers: Answer[];
  submission: Submission;
  answerResult: SubmittedAnswerResult;
  allowReattempt: boolean;
  variables: Variable[];
  matches: Match[];
  answerTolerance: number;
  isLastChanceEligible: boolean;
  questionDetailsUrl: string;
  canvasAccountId: number;

  constructor(private hateoas: HateOasService) {
    super();
  }

  /**
   * MOST (ALL?) OF THESE METHODS ARE LEGACY
   */

  setSubmissions(submissions: QuestionAnswerSubmission[]) {
    this.submissions = submissions;
    this.setLastSubmissionValue();
    this.setThisSubmissionValue();
  }

  getType() {
    return this.isMultipleChoice() ? 'MultipleChoice' : this.questionType;
  }

  isImmediateRetakeAvailable() {
    const link = this.hateoas.getLinkInterfaceForRel(this.links, 'Immediate_Reattempt');

    return link !== undefined && this.hasSubmittedForAttempt();
  }

  canAcknowledge() {
    return this.hateoas.getLinkInterfaceForRel(this.links, 'Acknowledge_attempt') !== undefined;
  }

  isFeedbackAvailable() {
    return this.hasSubmittedForAttempt() &&
        (
          this.hateoas.getLinkInterfaceForRel(this.links, 'Post_Feedback') ||
          this.hateoas.getLinkInterfaceForRel(this.links, 'Patch_Feedback')
        ) !== undefined;
  }

  getSubmitButtonText() {
    let text = '';
    switch (this.getType()) {
      case 'Announcement':
        text = 'Confirm';
        break;
      default:
        text = 'Submit your answer';
    }

    if (this.submissionAttempts > 0 && this.remainingAttempts == 1) {
      text = 'Submit your answer (last try)';
    }

    return text;
  }

  isStatisticsAvailable() {
    return this.hateoas.getLinkInterfaceForRel(this.links, 'Get_QuestionStats') !== undefined;
  }

  canSubmit() {
    return this.hateoas.getLinkInterfaceForRel(this.links, 'Post_submission') !== undefined
      && !this.hasSubmittedForAttempt();
  }

  hasSubmittedForAttempt() {
    return this.submissions
      && this.submissions.length
      && this.submissions.filter(s => s.id === this.attemptId).length > 0;
  }

  private setThisSubmissionValue() {
    if (this.submissions !== undefined && this.submissions.length) {
      const submission = this.submissions.filter(s => s.id === this.attemptId);
      if (submission.length) {
        this.thisSubmission = submission[0];
        this.thisSubmissionValue = this.thisSubmission.value;
      }
    }
  }

  private setLastSubmissionValue() {
    if (this.lastSubmissionId !== undefined && this.submissions !== undefined) {
      const last = this.submissions.filter(s => s.id === this.lastSubmissionId);
      if (last.length) {
        this.lastSubmissionValue = last[0].value;
        this.lastSubmission = last[0];
        this.lastSubmissionValue = this.lastSubmission.value;
      }
    }
  }

  private isMultipleChoice() {
    return ['TrueOrFalse', 'MultipleChoice'].indexOf(this.questionType) !== -1;
  }
}
