import {AfterViewChecked, Component, ElementRef, OnInit, Query, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ReplacementFieldsBaseQuestionComponent} from '../base-question/base-question.component';
import {HashStringIntAnswer} from '../../../models/answered-question';
import {MultiDropdownsPipe} from '../../../shared/pipes/multi-dropdowns.pipe';

@Component({
  selector: 'app-multiple-dropdowns-question',
  templateUrl: './multiple-dropdowns-question.component.html',
  styleUrls: ['./multiple-dropdowns-question.component.scss']
})
export class MultipleDropdownsQuestionComponent extends ReplacementFieldsBaseQuestionComponent implements OnInit, AfterViewChecked {
  @ViewChildren('formReplacementField') formReplacementField: QueryList<ElementRef>;

  handleSubmit() {
    this.toggleQuestionSubmitting();
    this.disableElements();
    const form = this.customForm.nativeElement;
    const inputs = form.querySelectorAll('select');
    const answer = new HashStringIntAnswer();
    const response = {};
    inputs.forEach(select => {
      response[select.name] = select.value;
    });
    answer.value = response;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }

  getElements(): any[] {
    const form = this.customForm.nativeElement;
    return [...form.querySelectorAll('select')];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formString = new MultiDropdownsPipe()
      .transform(this.formStringParts, this.replacementVariables, this.multipleForm, this.answerResult$.value);
  }

  setAnswer() {
    this.formString = new MultiDropdownsPipe()
      .transform(this.formStringParts, this.replacementVariables, this.multipleForm, this.answerResult$.value);
  }

  ngAfterViewChecked(): void {
    if (this.formReplacementField.length) {
      this.setFormFieldListener();
    }
  }

  setFormFieldListener(): void {
    const inputs = this.getElements();
    inputs.forEach(i => i.addEventListener('change', (event) => {
      const unanswered = inputs.filter(input => input.value === '');
      this.formIsValid = unanswered.length === 0;
    }));
  }

  correctAnswerString() {
    if (this.canShowCorrectAnswer()) {
      return new MultiDropdownsPipe()
        .transformForPreviewWithCorrectValues(
          this.formStringParts,
          this.replacementVariables,
          this.multipleForm,
          this.question$.value,
          true);
    }
  }
}
