import { Component, OnInit } from '@angular/core';
import { environment} from '../environments/environment';
import { ApplicationInsightsLoggingService } from './services/application-insights-logging.service';
import { StorageService } from './services/storage.service';
import {ActivatedRoute} from '@angular/router';
import {map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'QuizTime';

  constructor(
    private appInsightsLogging: ApplicationInsightsLoggingService,
    private storageService: StorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Until/unless we use an internal db we want to keep the localstorage clean.
    const localexpiry = localStorage.getItem('QuizTime-expiry');
    const in5Days = new Date(new Date(). getTime() + (5 * 24 * 60 * 60 * 1000));
    if (localexpiry == null) {
      localStorage.setItem('QuizTime-expiry', Date.parse(in5Days.toUTCString()).toString());
    }
    else {
      const now = Date.parse(new Date().toUTCString());
      const asInt = parseInt(localexpiry, 10);
      if (parseInt(localexpiry, 10) <= now) {
        const authRedirect = localStorage.getItem('authRedirect');
        localStorage.clear();
        localStorage.setItem('QuizTime-expiry', Date.parse(in5Days.toUTCString()).toString());
        if (authRedirect != null) {
          localStorage.setItem('authRedirect', authRedirect);
        }
      }
    }
    this.storageService.clear('platform-user');
    this.route.queryParams
      .pipe(
        map(params => {
          if (params.obo !== undefined) {
            localStorage.setItem('platform-user', params.obo);
          }
        })
      ).subscribe();
  }
}
