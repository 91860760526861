import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AnsweredQuestion} from '../../models/answered-question';
import {LearnerQuestionLogViewModel} from "../../models/learner-question-log-view.model";

@Injectable()
export class QuestionService {

  API_URL = environment.apiBase;
  VS_URL = 'https://localhost:44398/api';

  constructor(private http: HttpClient) {}

  // Todo use of 'any' here can't be right.
  getAttemptRequest(requestId: string): Observable<any> {
    return this.http
      .get(this.API_URL + `/api/attempts/${requestId}`);
  }

  get(href: string): Observable<any> {
    return this.http
      .get(this.API_URL + href);
  }

  post(href: string, body: object): Observable<any> {
    return this.http
      .post(this.API_URL + href, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }

  getQuestion(href: string): Observable<any> {
    const apiPath = href.includes(this.VS_URL) ? href.substr(this.VS_URL.length) : href;
    return this.http
      .get(this.API_URL + apiPath)
      .pipe(
        map((response: Response) => response)
      );
  }

  getQuestionStatistics(href: string): Observable<any> {
    return this.http
      .get(this.API_URL + href)
      .pipe(
        map((response: Response) => response)
      );
  }

  submitAnswerBySlug(slug: string, answer: AnsweredQuestion): Observable<any> {
    return this.http
      .post(this.API_URL + `/api/v2/lms/questions/answer?slug=${slug}`, answer);
  }

  submitDemoAnswer(answer: AnsweredQuestion): Observable<any> {
    return this.http
      .post(this.API_URL + `/api/v2/lms/questions/demo/answer`, answer);
  }

  getQuestionForPreview(platformId: number, courseId: number, quizId: number, questionId: number): Observable<any> {
    return this.http.get(this.API_URL + `/api/v2/ltiPlatforms/platform/1/course/${courseId}/quiz/${quizId}/questions/${questionId}`);
  }

  getLmsDemoQuestion(questionId: string): Observable<any> {
    return this.http
      .get(this.API_URL + `/api/v2/lms/questions/demo/${questionId}`);
  }

  getLmsQuestionInfo(slug: string): Observable<any> {
    return this.http
      .get(this.API_URL + `/api/v2/lms/questions/info?slug=${slug}`);
  }

  getLmsQuestionDetails(detailsUrl: string): Observable<any> {
    return this.http
      .get(this.API_URL + detailsUrl);
  }

  sendView(slug: string): Observable<any> {
    return this
      .http
      .put<LearnerQuestionLogViewModel>(this.API_URL + `/api/v2/lms/questions/view?slug=${slug}`, null);
  }

  setViewSessionEnded(id: number): Observable<any> {
    return this.http.put(this.API_URL + `/api/v2/lms/questions/endView?lqlvId=${id}`, null)
  }

  getLmsQuestionAnswerResult(slug: string, attemptNo: number): Observable<any> {
    let params = `slug=${slug}`;
    if (attemptNo != null) {
      params += `&attempt=${attemptNo}`;
    }
    return this.http
      .get(this.API_URL + `/api/v2/lms/questions/result?${params}`);
  }

  postLmsQuestionAnswer(attemptId: string, enrolleeId: string, answer: string): Observable<any> {
    return this.http
      .get(this.API_URL + `/learner-answer/answeredCorrectRemainingAttempt`)
      .pipe(
        map((response: Response) => response)
      );
  }

  uploadFileForAnswer(slug: string, file: File) {
      const formData = new FormData();
      formData.append('fileForAnswer', file);
      return this.http
        .post(this.API_URL + `/api/v2/lms/questions/answer/file?slug=${slug}`, formData);
  }
}
