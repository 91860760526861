<label for="questionAnswer">
  <div class="visually-hidden">Answer:</div>
</label>
<input
  type="text"
  ngModel
  required
  id="questionAnswer"
  name="questionAnswer"
  [disabled]="true"
  [value]=""
>
<p></p><i>Formula questions vary in answer for each student. This question will deliver under the following rules:</i> <p></p>
<div *ngFor="let variable of question.variables; index as id">
  {{variable.name}} will range between {{variable.min}} and {{variable.max}}
</div>
<div *ngIf="question.answerTolerance == 0">
  Answer must be exact.
</div>
<div *ngIf="question.answerTolerance != 0">
  Answers within a range of {{question.answerTolerance}} will be accepted as correct.
</div>
