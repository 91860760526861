import {Injectable} from "@angular/core";
import {QuestionService} from "./questions/question.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class QuestionViewSessionManagerService {
  constructor(private questionService: QuestionService) {}

  /**
   * This is a helper for tracking "sessions".
   * Ideally this is replaced by analytics tools in the future.
   * @param urlSlug
   */
  sendView(urlSlug: string): Observable<any> {
    const sessionStorageKey = `_slug-session-${urlSlug}`;
    let timeoutId;
    // Start session timer and window unload
    const endView = (event) => {
      const checkAndEndView = () => {
        const inStorage = sessionStorage.getItem(sessionStorageKey);
        if (inStorage != null) {
          const parsed = JSON.parse(inStorage);
          if (parsed.id != null) {
            this.questionService.setViewSessionEnded(parsed.id).subscribe();
          }
          sessionStorage.removeItem(sessionStorageKey);
          window.removeEventListener('visibilitychange', endView);
          window.removeEventListener('beforeunload', endView);
          window.clearTimeout(timeoutId);
        }
      }
      if (event.type === 'visibilitychange' || event.type === 'beforeunload') {
        return checkAndEndView();
      }
    }
    window.addEventListener('visibilitychange', endView);
    window.addEventListener('beforeunload', endView);
    // 30 minutes.
    timeoutId = window.setTimeout(endView, 1800000);

    return this.questionService.sendView(urlSlug).pipe(
      map(data => {
        const inStorage = sessionStorage.getItem(sessionStorageKey);
        if (inStorage == null) {
          sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
        }
      })
    );
  }
}
