import { Component, OnInit } from '@angular/core';
import {UserEmailPreferencesInterface} from '../models/users/user-email-preferences.interface';
import {UserInterface} from '../models/users/user.interface';
import {UsersService} from '../services/users/users.service';
import {UntypedFormBuilder} from '@angular/forms';
import {GlobalLoaderService} from '../services/global-loader.service';
import {catchError, concatMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-email-preferences-container',
  templateUrl: './email-preferences-container.component.html',
  styleUrls: ['./email-preferences-container.component.scss']
})
export class EmailPreferencesContainerComponent {
  emailPreferences: UserEmailPreferencesInterface;
  userEmail: string;
  preferencesUpdated = false;
  lookupError: string;

  constructor(private usersService: UsersService, private fb: UntypedFormBuilder, private globalLoader: GlobalLoaderService) {}

  emailLookupSubmit(val): void {
    this.lookupError = undefined;
    this.globalLoader.isLoading.next(true);
    this.userEmail = val.email;
    this.usersService.getUsersEmailPreferences(val.email).pipe(
      tap(emailPreferences => {
        if (emailPreferences == null) {
          throw Error('Not able to get email preferences for that email address.');
        }
        this.emailPreferences = emailPreferences;
      }),
      catchError(err => {
        this.lookupError = err.toString().replace('Error:', '');
        return of([]);
      })
    ).subscribe(() => {
      this.globalLoader.isLoading.next(false);
    });
  }

  emailPreferencesSubmit(): void {
    this.globalLoader.isLoading.next(true);
    this.usersService
      .putUsersEmailPreferences(this.userEmail, this.emailPreferences).subscribe(() => {
        this.globalLoader.isLoading.next(false);
        this.preferencesUpdated = true;
    });
  }
}
