<form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
  <label for="questionAnswer" class="visually-hidden">Your file:</label>
  <input
    name="questionAnswer"
    id="questionAnswer"
    type="file"
    class="file-input"
    required
    (change)="onFileSelected($event)" #fileUpload>
  <div class="file-upload" *ngIf="fileSubmissionId < 1">
    <span *ngIf="file">{{ file.name }}</span>
    <span *ngIf="!file">No file uploaded yet.</span>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="fileUpload.click()">Select file</button>
  </div>
  <hr />
  <div class="question-answer-actions">
    <button
      disabled
      class="btn btn-primary">Submit</button>
  </div>
</form>
