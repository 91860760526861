import { Component, OnInit } from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {StringQuestionAnswer} from '../../../models/answered-question';

@Component({
  selector: 'app-short-answer-question',
  templateUrl: './short-answer-question.component.html',
  styleUrls: ['./short-answer-question.component.scss']
})
export class ShortAnswerQuestionComponent extends BaseQuestionComponent implements OnInit {
  questionAnswer: any;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.answerResult != null) {
      this.questionAnswer = this.answerResult.answer;
    }
  }

  handleSubmit(value, isValid) {
    this.toggleQuestionSubmitting();
    const answer = new StringQuestionAnswer();
    answer.value = value.questionAnswer;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }

  setAnswer() {
    if (this.answerResult != null) {
      this.questionAnswer = this.answerResult.answer;
    }
  }

  get correctAnswerText() {
    const first = this.question$.value.correctAnswers[0];
    if (first.html !== '' && first.html !== null) {
      return first.html;
    }

    return first.text;
  }

  answerHasComments(): boolean {
    return this.answerResult$.value.answerDetails.length && this.answerResult$.value.answerDetails[0].commentsHtml !== '';
  }

  answerComments(): string {
    return this.answerResult$.value.answerDetails[0].commentsHtml;
  }
}
