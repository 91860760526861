import { Component, OnInit } from '@angular/core';
import { StringQuestionAnswer } from 'src/app/models/answered-question';
import { BaseQuestionComponent } from '../base-question/base-question.component';

@Component({
  selector: 'app-formula-question',
  templateUrl: './formula-question.component.html',
  styleUrls: ['./formula-question.component.scss']
})
export class FormulaQuestionComponent extends BaseQuestionComponent implements OnInit {
  questionAnswer: any;
  ngOnInit(): void {
    super.ngOnInit();
  }

  setAnswer() {
    if (this.answerResult != null) {
      this.questionAnswer = this.answerResult.answer;
    }
  }

  handleSubmit(value, isValid) {
    this.toggleQuestionSubmitting();
    const answer = new StringQuestionAnswer();
    answer.value = value.questionAnswer;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }
}
