import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { QuestionPresenterModule } from './question-presenter/question-presenter.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { GlobalLoaderComponent } from './shared/components/global-loader/global-loader.component';
import {GlobalLoaderService} from './services/global-loader.service';
import {HateOasService} from './shared/utilities/hateoas';
import { ApplicationInsightsLoggingService } from './services/application-insights-logging.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {NoCacheHeadersInterceptor} from './shared/interceptors/no-cache.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { EmailPreferencesContainerComponent } from './email-preferences-container/email-preferences-container.component';
import { EmailPreferencesLookupComponent } from './email-preferences-container/email-preferences-lookup/email-preferences-lookup.component';
import { EmailPreferencesFormComponent } from './email-preferences-container/email-preferences-form/email-preferences-form.component';
import { PlatformAuthenticationComponent } from './platform-authentication/platform-authentication.component';
import {LoggerService} from './services/logger.service';
import { ErrorMessageComponent } from './shared/components/error-message/error-message.component';
import { PlatformQuestionFeedbackComponent } from './platform-question/platform-question-feedback/platform-question-feedback.component';
import { PlatformQuestionContainerComponent } from './platform-question/platform-question-container/platform-question-container.component';
import { TextOnlyQuestionComponent } from './platform-question/question-types/text-only-question/text-only-question.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// tslint:disable-next-line:import-spacing
import {MultipleChoiceQuestionComponent}
  from './platform-question/question-types/multiple-choice-question/multiple-choice-question.component';
// tslint:disable-next-line:import-spacing
import { MultipleAnswersQuestionComponent }
  from './platform-question/question-types/multiple-answers-question/multiple-answers-question.component';
import { NumericQuestionComponent } from './platform-question/question-types/numeric-question/numeric-question.component';
import { EssayQuestionComponent } from './platform-question/question-types/essay-question/essay-question.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import { ShortAnswerQuestionComponent } from './platform-question/question-types/short-answer-question/short-answer-question.component';
// tslint:disable-next-line:max-line-length
import { MultipleDropdownsQuestionComponent } from './platform-question/question-types/multiple-dropdowns-question/multiple-dropdowns-question.component';
import { SelectListComponent } from './platform-question/question-types/select-list/select-list.component';
import {SafeHtmlPipe} from './shared/pipes/safe-html.pipe';
// tslint:disable-next-line:max-line-length
import { MultipleBlanksQuestionComponent } from './platform-question/question-types/multiple-blanks-question/multiple-blanks-question.component';
import {MultiDropdownsPipe} from './shared/pipes/multi-dropdowns.pipe';
import {MultiTextPipe} from './shared/pipes/multi-text.pipe';
import { FormulaQuestionComponent } from './platform-question/question-types/formula-question/formula-question.component';
import { MomentTzPipe } from './shared/pipes/moment-tz.pipe';
import { MatchingQuestionComponent } from './platform-question/question-types/matching-question/matching-question.component';
import { FileUploadQuestionComponent } from './platform-question/question-types/file-upload-question/file-upload-question.component';
import { PlatformQuestionPreviewerComponent } from './platform-question/platform-question-previewer/platform-question-previewer.component';
import { MultipleBlanksQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/multiple-blanks-question/multiple-blanks-question.component';
import { FormulaQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/formula-question/formula-question.component';
import { MatchingQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/matching-question/matching-question.component';
import { FileUploadQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/file-upload-question/file-upload-question.component';
import { TextOnlyQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/text-only-question/text-only-question.component';
import { MultipleAnswersQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/multiple-answers-question/multiple-answers-question.component';
import { NumericQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/numeric-question/numeric-question.component';
import { EssayQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/essay-question/essay-question.component';
import { ShortAnswerQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/short-answer-question/short-answer-question.component';
import { MultipleDropdownsQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/multiple-dropdowns-question/multiple-dropdowns-question.component';
import { MultipleChoiceQuestionPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/multiple-choice-question/multiple-choice-question.component';
import { SelectListPreviewerComponent } from './platform-question/platform-question-previewer/previewer-question-types/select-list/select-list.component';
import { PlatformQuestionDisplayComponent } from './platform-question/platform-question-display/platform-question-display.component';
import {
  BaseQuestionComponent,
  ReplacementFieldsBaseQuestionComponent
} from './platform-question/question-types/base-question/base-question.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonLoaderComponent } from './shared/components/button-loader/button-loader.component';
import { SiteHeaderComponent } from './site-header/site-header.component';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        GlobalLoaderComponent,
        EmailPreferencesContainerComponent,
        EmailPreferencesLookupComponent,
        EmailPreferencesFormComponent,
        MultipleChoiceQuestionComponent,
        PlatformAuthenticationComponent,
        ErrorMessageComponent,
        PlatformQuestionFeedbackComponent,
        PlatformQuestionContainerComponent,
        TextOnlyQuestionComponent,
        MultipleAnswersQuestionComponent,
        NumericQuestionComponent,
        EssayQuestionComponent,
        ShortAnswerQuestionComponent,
        MultipleDropdownsQuestionComponent,
        SelectListComponent,
        MultiDropdownsPipe,
        MultiTextPipe,
        SafeHtmlPipe,
        MomentTzPipe,
        MultipleBlanksQuestionComponent,
        FormulaQuestionComponent,
        MatchingQuestionComponent,
        FileUploadQuestionComponent,
        PlatformQuestionPreviewerComponent,
        MultipleBlanksQuestionPreviewerComponent,
        FormulaQuestionPreviewerComponent,
        MatchingQuestionPreviewerComponent,
        FileUploadQuestionPreviewerComponent,
        TextOnlyQuestionPreviewerComponent,
        MultipleAnswersQuestionPreviewerComponent,
        NumericQuestionPreviewerComponent,
        EssayQuestionPreviewerComponent,
        ShortAnswerQuestionPreviewerComponent,
        MultipleDropdownsQuestionPreviewerComponent,
        MultipleChoiceQuestionPreviewerComponent,
        SelectListPreviewerComponent,
        PlatformQuestionDisplayComponent,
        BaseQuestionComponent,
        ReplacementFieldsBaseQuestionComponent,
        ButtonLoaderComponent,
        SiteHeaderComponent
    ],
    imports: [
        BrowserModule,
        FontAwesomeModule,
        AppRoutingModule,
        QuestionPresenterModule,
        NgbModalModule,
        FormsModule,
        ReactiveFormsModule,
        TextFieldModule,
        CommonModule,
        BrowserAnimationsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot()
    ],
    providers: [
        HateOasService,
        GlobalLoaderService,
        ApplicationInsightsLoggingService,
        {
            provide: ErrorHandler,
            useClass: LoggerService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHeadersInterceptor,
            multi: true
        },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: PlatformUserVerificationInterceptor,
        //   multi: true
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ErrorInterceptor,
        //     multi: true
        // }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
