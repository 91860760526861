import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionPreviewerComponent } from './components/question/question-previewer.component';
import { QuestionPresenterComponent } from './containers/question-presenter/question-presenter.component';
import { QuestionComponent } from './components/question/question.component';
import { FormsModule } from '@angular/forms';
import { QuestionPreviewerService } from '../services/question-previewer/question-previewer.service';
import { QuestionService } from '../services/questions/question.service';
import { HttpClientModule } from '@angular/common/http';
import { LearnerAnswerResultComponent } from './components/learner-answer-result/learner-answer-result.component';
import { QuestionFooterComponent } from './components/question-footer/question-footer.component';
import { QuestionFeedbackComponent } from './components/question-feedback/question-feedback.component';
import { QuestionStatisticsComponent } from './components/question-statistics/question-statistics.component';
import { FeedbackService } from '../services/feedbacks/feedback.service';
import { QuestionAcknowledgeComponent } from './components/question-acknowledge/question-acknowledge.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuizCompletionComponent } from './components/quiz-completion/quiz-completion.component';
import { QuestionStatisticsService } from '../services/question-statistics/question-statistics-service';
import { LmsQuestionPreviewerComponent } from './components/question/lms-question-previewer.component';
import { NgChartsModule } from 'ng2-charts';


@NgModule({
    declarations: [
        QuestionPresenterComponent,
        QuestionComponent,
        QuestionPreviewerComponent,
        LmsQuestionPreviewerComponent,
        LearnerAnswerResultComponent,
        QuestionFooterComponent,
        QuestionFeedbackComponent,
        QuestionStatisticsComponent,
        QuestionAcknowledgeComponent,
        QuizCompletionComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        FontAwesomeModule,
        NgChartsModule
    ],
    providers: [
        QuestionPreviewerService,
        QuestionService,
        FeedbackService,
        QuestionStatisticsService
    ]
})
export class QuestionPresenterModule {}
