import {AfterViewChecked, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ReplacementFieldsBaseQuestionComponent} from '../base-question/base-question.component';
import {HashStringStringAnswer} from '../../../models/answered-question';
import {MultiTextPipe} from '../../../shared/pipes/multi-text.pipe';
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-multiple-blanks-question',
  templateUrl: './multiple-blanks-question.component.html',
  styleUrls: ['./multiple-blanks-question.component.scss']
})
export class MultipleBlanksQuestionComponent extends ReplacementFieldsBaseQuestionComponent implements OnInit, AfterViewChecked {
  @ViewChildren('formReplacementField') formReplacementField: QueryList<ElementRef>;
  private inputting = new Subject();
  handleSubmit() {
    this.toggleQuestionSubmitting();
    this.disableElements();
    const form = this.customForm.nativeElement;
    const inputs = form.querySelectorAll('input[type="text"]');
    const answer = new HashStringStringAnswer();
    const response = {};
    inputs.forEach(input => {
      response[input.name] = input.value;
    });
    answer.value = response;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }

  setAnswer() {
    this.formString = new MultiTextPipe()
      .transform(this.formStringParts, this.replacementVariables, this.multipleForm, this.answerResult$.value);
  }

  formListener(): void {}

  ngOnInit(): void {
    super.ngOnInit();
    this.inputting.pipe(
      debounceTime(200)
    ).subscribe(d => {
      const inputs = this.getElements();
      const unanswered = inputs.filter(input => input.value === '');
      this.formIsValid = unanswered.length === 0;
    });
  }

  correctAnswerString(): string {
    if (this.canShowCorrectAnswer()) {
      return new MultiTextPipe()
        .transformForPreviewWithCorrectValues(this.formStringParts, this.replacementVariables, this.multipleForm, this.question$.value);
    }
  }

  ngAfterViewChecked(): void {
    if (this.formReplacementField.length) {
      this.setFormFieldListener();
    }
  }

  setFormFieldListener(): void {
    const inputs = this.getElements();
    inputs.forEach(i => {
      i.removeAllListeners();
      i.addEventListener('keyup', e => this.inputting.next(e.target.value));
    });
  }

  getElements() {
    const form = this.customForm.nativeElement;
    return [...form.querySelectorAll('input[type=text]')];
  }
}
