import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent {
  @Input() canvasAccount: number;
  public catalogUrl = environment.catalogUrl;
  public supportUrl = `${this.catalogUrl}info/help#contact-form`
}
