import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  catalogUrl = environment.catalogUrl;
  supportUrl = `${this.catalogUrl}info/help#contact-form`;
  constructor() { }

  ngOnInit() {
  }

}
