import {Pipe, PipeTransform} from '@angular/core';
import {ReplacementVariable} from '../../platform-question/question-types/base-question/base-question.component';
import {UntypedFormGroup} from '@angular/forms';
import {SubmittedAnswerResult} from '../../models/submitted-answer-result';
import { Question } from 'src/app/models/question';

@Pipe({
  name: 'multitext'
})
export class MultiTextPipe implements PipeTransform {
  transform(value: string[], replacementVars: ReplacementVariable[], form: UntypedFormGroup, answerResult: SubmittedAnswerResult): any {
    let compiledString = '';
    value.forEach(v => {
      if (replacementVars.find(r => r.withBrackets === v) === undefined) {
        compiledString += v;
      } else {
        const rv = replacementVars.find(rvf => rvf.withBrackets === v);
        const defaultValue = answerResult != null ? answerResult.answer[rv.asString] : '';
        const disabled = answerResult != null ? 'disabled=disabled' : '';
        const input = `<label for="field-${rv.asString}" class="visually-hidden">${rv.asString}</label><input type="text" id="field-${rv.asString}" value="${defaultValue}" name="${rv.asString}" ${disabled}>`;

        compiledString += input;
      }
    });

    return compiledString;
  }
  transformForPreviewWithCorrectValues(value: string[], replacementVars: ReplacementVariable[], form: UntypedFormGroup, question: Question): any {
    let compiledString = '';
    value.forEach(v => {
      if (replacementVars.find(r => r.withBrackets === v) === undefined) {
        compiledString += v;
      } else {
        const rv = replacementVars.find(rvf => rvf.withBrackets === v);
        const correctAnswer = question.correctAnswers.find(item => {
          return item.blankId === rv.asString;
        });

        const disabled = question != null ? 'disabled=disabled' : '';
        const input = `<input type="text" value="${correctAnswer.text}" name="${rv.asString}" ${disabled}>`;

        compiledString += input;
      }
    });

    return compiledString;
  }
}

