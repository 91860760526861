<div
  class="alert alert-warning"
  role="alert"
  *ngIf="question.isExpired && (question.submissionAttempts == undefined || question.submissionAttempts < 1)"
>
  Question expired {{ question.expirationDate | date: 'MMM d, yyyy' }}. Review the explanation below.
</div>
<div class="text-center" *ngIf="this.question.getType() === 'Announcement'">
  <h4 class="text-muted"><fa-icon [icon]="faBullhorn"></fa-icon> Announcement</h4>
</div>
<div class="question card">
  <div class="card-header">
    <div class="card-title text-center question__stem">{{ question.stem }} </div>
  </div>
  <div class="card-body">
    <div class="question__lead-in" [innerHTML]="questionLeadIn"></div>
    <form (ngSubmit)="handleSubmit(submissionForm.value, submissionForm.valid)" #submissionForm="ngForm" novalidate>
      <div *ngIf="this.question.getType() === 'MultipleChoice'">
        <div class="form-check" *ngFor="let choice of questionChoices; index as id">
          <input
            ngModel
            required
            class="form-check-input"
            type="radio"
            name="questionAnswer"
            id="choice-{{ id }}"
            [value]="id.toString()"
            [attr.disabled]="!this.question.canSubmit() ? true : null"
            [(ngModel)]="this.question.thisSubmissionValue"
            [class.is-correct-answer]="(
              (this.question.thisSubmission !== undefined
                && this.question.thisSubmission.isCorrect
                && this.question.thisSubmissionValue == id.toString())
              ||
              (this.question.correctAnswer !== undefined && this.question.correctAnswer === id.toString()))"
          >
          <label class="form-check-label" for="choice-{{ id }}" [innerHTML]="choice"></label>
        </div>
      </div>
      <div *ngIf="this.question.getType() === 'Announcement'">
        <input ngModel type="hidden" name="questionAnswer" [(ngModel)]="announcementValue">
      </div>
      <div *ngIf="this.question.getType() === 'OpenText'">
        <div class="form-group">
          <textarea ngModel [(ngModel)]="this.question.thisSubmissionValue" class="form-control" name="questionAnswer" id="" cols="30" rows="10"></textarea>
        </div>
      </div>
<!--      <div *ngIf="this.question.questionType !== 'MultipleChoice'">-->
<!--        Question type not yet implemented.  Note that Announcements and MultipleSelectQuestions are not currently used in production.-->
<!--      </div>-->
      <hr>
      <button
        *ngIf="this.question.canSubmit()"
        [disabled]="!submissionForm.valid || isSubmitting"
        class="btn btn-primary question-submit">
        {{ this.question.getSubmitButtonText() }}</button>
    </form>
  </div>
</div>
<div *ngIf="this.question.contributorName !== undefined">
  <p>
    <small>Contributor: <strong>{{ this.question.contributorName }}</strong></small>
  </p>
</div>
<div id="question-submission-notification" *ngIf="!this.question.canSubmit()">
  <div *ngIf="this.showQuestionSubmissionNotification()">
    <h5
      class="question-submission-notification__correct text-success"
      *ngIf="this.question.thisSubmission !== undefined && this.question.thisSubmission.isCorrect" >You are correct!</h5>
    <h5
      class="question-submission-notification__incorrect text-danger"
      *ngIf="this.question.thisSubmission !== undefined && !this.question.thisSubmission.isCorrect" >Your answer was incorrect</h5>
  </div>
  <div *ngIf="this.question.thisSubmission !== undefined && (this.question.nextAttemptId === undefined || this.question.nextAttemptId === this.question.attemptId)">
    <p>
      <span *ngIf="this.answerResult !== undefined && this.answerResult.remainingAttempts < 1">{{ this.answerResult.totalQuestions - this.answerResult.remainingQuestions }} out of {{ this.answerResult.totalQuestions }} questions completed. </span>
      <a href="https://quiztimehealth.com/quiz-activity" target="_blank">Click Here</a> to view your credits, please allow up to 24 hours after completing a question for the credits to include that question.
    </p>
  </div>
  <p *ngIf="this.question.questionType === 'Announcement'" class="text-center">
    <button href="#" class="btn btn-secondary btn-lg btn-fill disabled">Confirmed <fa-icon [icon]="faCheck"></fa-icon></button></p>
</div>

<div *ngIf="question.explanation && !question.canSubmit()">
  <p *ngIf="answerResult !== undefined && answerResult.isExplanationRead !== true">Please read the explanation below.</p>
  <div [ngClass]="{
        'question-explanation__wrapper': true,
        'question-explanation--is-correct': this.question.thisSubmission !== undefined && this.question.thisSubmission.isCorrect,
        'question-explanation--is-incorrect': this.question.thisSubmission !== undefined && !this.question.thisSubmission.isCorrect
      }">
    <h2>Explanation</h2>
    <div class="question-explanation__explanation" [innerHTML]="questionExplanation"></div>
  </div>
</div>
<div
  class="question-explanation__url-references"
  *ngIf="question.references !== undefined && question.references.length > 0">
      <h6 class="border-top pt-4 mt-4">External Resources:</h6>
      <ul>
        <li *ngFor="let reference of question.references">
          <a [href]="validateReference(reference.url)" target="_blank">{{ reference.title }} - {{ reference.url }}</a>
        </li>
      </ul>
    </div>

