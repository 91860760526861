<!--<div-->
<!--  class="question__lead-in"-->
<!--  [innerHTML]="questionDisplay.questionText | safeHtml">-->
<!--</div>-->
<div
  [froalaEditor]="options"
  id="questionAnswer"
  name="questionAnswer"></div>
<hr />
<div class="question-answer-actions">
  <button
    disabled
    class="btn btn-primary">Submit</button>
</div>
