import { UntypedFormGroup,  Validators, UntypedFormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseQuestionComponent } from '../../../question-types/base-question/base-question.component';

@Component({
  selector: 'app-matching-question-previewer',
  templateUrl: './matching-question.component.html',
  styleUrls: ['./matching-question.component.scss']
})
export class MatchingQuestionPreviewerComponent extends BaseQuestionComponent implements OnInit {
  multipleAnswersForm: UntypedFormGroup;
  selectForm: UntypedFormGroup;
  answerResultArray: any[];

  ngOnInit(): void {
    this.selectForm = new UntypedFormGroup({});
    this.question.answers.forEach(a => {
      let controlValue = '';
      if (this.answerResult != null) {
        this.answerResultArray = Object.values(this.answerResult.answer);

        controlValue = this.getSelectedMatchId(a.id);
      }

      this.selectForm.addControl(a.id.toString(), new UntypedFormControl(controlValue, [Validators.required]));
    })
  }

  getSelectedMatchId(answerId: number): string {
    const match = this.answerResultArray.find(e => e.answer_id === answerId.toString());

    return match.match_id;
  }

  correctAnswerText(matchId: number): string {
    const questionMatches = this.question$.value.matches;
    const correct = questionMatches.find(q => q.matchId === matchId);

    return correct.text;
  }

  handleSubmit() {}
}
