import {Component, Input} from '@angular/core';
import {GlobalLoaderService} from '../../../services/global-loader.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent {
  loadingMessage$ = new BehaviorSubject<string>(null);
  @Input() message: BehaviorSubject<string>;
  constructor(public globalLoaderService: GlobalLoaderService) {
    this.globalLoaderService.loadingMessage.subscribe(msg => {
      this.loadingMessage$.next(msg);
    });
  }
}
