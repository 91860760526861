import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChartDataset, ChartOptions} from 'chart.js';
import {QuestionStatisticsComponent} from '../question-statistics/question-statistics.component';
import {Question} from '../../../models/question';
import { QuestionFeedbackComponent } from '../question-feedback/question-feedback.component';

@Component({
  selector: 'app-question-footer',
  templateUrl: './question-footer.component.html',
  styleUrls: ['./question-footer.component.scss']
})
export class QuestionFooterComponent implements OnInit {

  @Input() question: Question;
  @Output() feedbackFormSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: NgbModal) {}
  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function(context) {
            let label = 'need to fix'

            return label
          },
          // label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          //   return data.labels[tooltipItem.index].toString();
          // },
          title: function(): string | string[] {
            return '';
          }
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
      }
    }
  };
  chartData: ChartDataset[] = [
    {
      label: 'All question Attempts',
      data: [17, 18, 18, 19],
      backgroundColor: [
        'red', 'red', 'red', 'red'
      ]
    }
  ];
  chartLabels = ['Red', 'Green', 'Blue', 'Yellow'];
  shouldShowQuestionComplete() {
    return !this.question.canSubmit() && !this.question.canAcknowledge() && !this.question.isImmediateRetakeAvailable();
  }

  ngOnInit() {
    this.chartData.concat(...[{ data: [23], backgroundColor: 'blue' }]);
  }

  open(content, question: Question) {
    const modalComponent = `${content}`;
    let modalRef;
    const modalOptions = {
      size: 'lg',
      windowClass: 'quiztime-modal'
    };
    switch (modalComponent) {
      case 'questionFeedback': modalRef = this.modalService.open(QuestionFeedbackComponent, modalOptions);
                               break;
      case 'questionStatistics': modalRef = this.modalService.open(QuestionStatisticsComponent, modalOptions);
                                 break;
      default: modalRef = this.modalService.open('Undefined Modal Action');
    }

    modalRef.componentInstance.question = question;
    modalRef.componentInstance.feedbackFormSubmitEvent.subscribe(() => this.feedbackFormSubmitEvent.emit());
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
