<div class="card">
  <div class="card-header text-center">
    <button type="button" class="close" data-dismiss="modal" (click)="onClick()">
      <span>×</span>
      </button>
    <div class="card-title"><h5 [innerHTML]="question.leadIn"></h5></div>
    <div class="Stars" *ngIf="this.starStyle !== undefined" [style]="starStyle">{{this.statistics.ratingAverage}}</div>
  </div>
    <div class="card-body">
        <canvas baseChart
        [data]="chartData"
        [labels]="chartLabels"
        [type]="chartType"
        [options]="options">
      </canvas>
    </div>
    Check the colors above - I've removed them
    <div class="card-footer text-center">{{this.statistics.attemptOneCorrect}} answered correctly on the first attempt.</div>
    <div *ngIf="this.statistics.attemptOneCorrect != this.statistics.correct" class="card-footer text-center">{{this.statistics.attemptTwoCorrect}} answered correctly on the second attempt.</div>
    <div class="card-footer text-center">{{this.statistics.incorrect}} answered incorrectly after both attempts.</div>
  </div>
