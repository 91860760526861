import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Feedback } from 'src/app/models/feedback';
import {environment} from '../../../environments/environment';
import { PlatformQuestionFeedback } from 'src/app/models/platform-question-feedback.interface';
import {catchError, tap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';

@Injectable()
export class FeedbackService {

  API_URL = environment.apiBase;
  VS_URL = 'https://localhost:44398/api';

  constructor(private http: HttpClient) {

  }

  getUserFeedback(href: string): Observable<Feedback> {
    return this.http
      .get<Feedback>(this.API_URL + href);
  }

  patchUserFeedback(href: string, body: Feedback): Observable<Feedback> {
    // Originally wanted to pass this body as a model, but my numbers type for rating was being made a string instead on call.
    return this.http
      .patch<Feedback>(this.API_URL + href,
        { "comments": body.comments, "rating": body.rating})
  }

  postUserFeedback(href: string, body: Feedback): Observable<Feedback> {
    return this.http
      .post<Feedback>(this.API_URL + href,
        {
          "comments": body.comments,
          "rating": body.rating
        })
  }

  getLmsUserFeedback(urlSlug: string): Observable<PlatformQuestionFeedback> {
    return this.http
      .get<PlatformQuestionFeedback>(this.API_URL + '/api/v2/platformQuestionFeedback/urlSlug/' + urlSlug)
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  putLmsUserFeedback(urlSlug: string, feedback: PlatformQuestionFeedback): Observable<PlatformQuestionFeedback> {
    return this.http
      .put<PlatformQuestionFeedback>(this.API_URL + '/api/v2/platformQuestionFeedback/urlSlug/' + urlSlug, feedback)
        .pipe(
          catchError(err => this.handleError(err))
        );
  }

  private handleError(err): Observable<any> {
    if (!environment.production) {
      console.log(err);
    }
    return of([]);
  }
}
