import {LinkInterface} from './link.interface';
import {HateOasService} from '../shared/utilities/hateoas';

export interface ApiInterface {
  links: LinkInterface[];
}

export class ApiBase implements ApiInterface {
  links: LinkInterface[];
}
