<footer class="question-footer border-top mt-4 p-4">
  <div class="text-center" *ngIf="question.isFeedbackAvailable()">
    <button (click)="open('questionFeedback', question)" class="btn btn-primary btn-dashboard margin-top-20">Give Us Your Feedback!</button>
  </div>
  <div class="text-center" *ngIf="question.isStatisticsAvailable()">
    <button (click)="open('questionStatistics', question)" class="btn btn-primary btn-dashboard margin-top-20">How have others answered this question?</button>
    <br><br>
  </div>
  <div class="text-center question-complete alert alert-success" role="alert" *ngIf="shouldShowQuestionComplete()">
    This has been marked complete.
    <p></p><p>Thank you for using QuizTime. </p> </div>
</footer>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">All question attempts</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="width: 100%;">
      <canvas
        baseChart
        [type]="'horizontalBar'"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
        [legend]="false">
      </canvas>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
