import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnBehalfOfService {
  private onBehalfOf = new BehaviorSubject(null);
  public onBehalfOf$ = this.onBehalfOf.asObservable();
  private externalAuthorization = new BehaviorSubject(null);
  public externalAuthorization$ = this.externalAuthorization.asObservable();
  public onBehalfOfValue = localStorage.getItem('platform-user') !== null ? localStorage.getItem('platform-user') : null;

  public update(value): void {
    this.onBehalfOf.next(value);
    this.onBehalfOfValue = value;
  }

  public setExternalAuthorization(value: string): void {
    this.externalAuthorization.next(value);
  }
}
