// New answered question model for submitting to Canvas
import {Submission} from './submission';
import {Question} from './question';

export class AnsweredQuestion {
  submission: Submission;
  submissionId: number;
  validationToken: string;
  attempt: number;
  answer: AnsweredQuestionAnswer[];
  questionType: string;
  platformQuizType: string;
}

export class AnsweredQuestionAnswer {
  id: number;
  submitted: IQuestionAnswer;
}

export interface IQuestionAnswer {
  value: any;
  question: Question;
}

export class StringQuestionAnswer implements IQuestionAnswer {
  value: string;
  question: Question;
}

export class IntQuestionAnswer implements IQuestionAnswer {
  value: number;
  question: Question;
}

export class HashStringStringAnswer implements IQuestionAnswer {
  value: {};
  question: Question;
}

export class HashStringIntAnswer implements IQuestionAnswer {
  value: {};
  question: Question;
}

export class MultipleIntQuestionAnswer implements IQuestionAnswer {
  value: number[];
  question: Question;
}

export class FileUploadAnswer implements IQuestionAnswer {
  value: number;
  question: Question;
}

