<nav class="navbar navbar-dark navbar-fixed-top navbar-expand-sm {{ canvasAccount != null ? 'canvas-account-' + canvasAccount : null }}">
  <div class="container">
    <a class="navbar-brand" href="{{ catalogUrl }}" target="_blank">
      <img class="logo" src="../assets/images/QuizTime_NEW-White.png" alt="QuizTime logo"></a>
    <ul class="nav navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Need Help?
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="{{ catalogUrl }}info/help" target="_blank">Frequently Asked Questions</a>
          <a class="dropdown-item" href="{{ supportUrl }}" target="_blank">Contact Support</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
