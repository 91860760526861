import { Component, OnInit } from '@angular/core';
import {BaseQuestionComponent} from '../base-question/base-question.component';
import {HashStringIntAnswer} from '../../../models/answered-question';
import {FormArray, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-matching-question',
  templateUrl: './matching-question.component.html',
  styleUrls: ['./matching-question.component.scss']
})
export class MatchingQuestionComponent extends BaseQuestionComponent implements OnInit {
  answerResultArray: any[];
  selectForm: UntypedFormGroup;

  handleSubmit() {
    this.toggleQuestionSubmitting();
    const answer = new HashStringIntAnswer();
    const response = [];
    const entries = Object.entries(this.selectForm.value);
    entries.forEach(v => {
      response.push({
        answer_id: v[0],
        match_id: v[1]
      });
    });
    answer.value = response;
    setTimeout(() => {
      this.answerSubmitted.emit(answer);
    }, 1);
  }

  setAnswer() {
    const controls = this.selectForm.controls;
    const answers = this.answerResult$.value.answer as Array<HashStringIntAnswer>;
    answers.forEach(a => {
      const aValue = a as any;
      controls[aValue.answer_id].patchValue(aValue.match_id);
      controls[aValue.answer_id].disable();
    });
  }

  getSelectedMatchId(answerId: number): string {
    const match = this.answerResultArray.find(e => e.answer_id === answerId.toString());

    return match.match_id;
  }

  correctAnswerText(matchId: number): string {
    const questionMatches = this.questionDisplay.matches;
    const correct = questionMatches.find(q => q.matchId === matchId);

    return correct.text;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.answerResult != null) {
      this.answerResultArray = Object.values(this.answerResult.answer);
    }
    this.selectForm = new UntypedFormGroup({});
    this.questionDisplay.answers.forEach(a => {
      let controlValue = '';
      if (this.answerResult$.value != null) {
        controlValue = this.getSelectedMatchId(a.id);
      }
      this.selectForm.addControl(
        a.id.toString(),
        new UntypedFormControl(controlValue, [Validators.required]));
    });
  }
}
