<ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
  <label for="questionAnswer" class="visually-hidden">Your answer:</label>
  <input
    type="number"
    ngModel
    required
    id="questionAnswer"
    name="questionAnswer"
    [disabled]="true"
    [value]=""
  >
  <p></p><i>Accepted answers for this question:</i><p></p>
  <div *ngFor="let answer of useQuestion.answers; index as id">
    <div *ngIf="answer.numericalAnswerType == 'exact_answer'">
        {{answer.exact}}, exactly.
    </div>
    <div *ngIf="answer.numericalAnswerType == 'range_answer'">
      A range between {{answer.start}} and {{answer.end}}.
    </div>
    <div *ngIf="answer.numericalAnswerType == 'precision_answer'">
      Approximately {{answer.approximate}} with a precision of {{answer.precision}}
    </div>
    <div
    class="answer-comments alert alert-success"
    *ngIf="answer.commentsHtml != null && answer.commentsHtml != ''"
    [innerHTML]="answer.commentsHtml | safeHtml">
    </div>
  </div>
</ng-template>
<ng-template
  [ngTemplateOutlet]="questionFormTemplate"
  [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
></ng-template>
