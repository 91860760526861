import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Answer, Question} from '../../../models/question';
import {SubmittedAnswerResult} from '../../../models/submitted-answer-result';
import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';
import {MultiTextPipe} from '../../../shared/pipes/multi-text.pipe';

@Component({
  selector: 'app-base-question-component',
  template: ''
})
export class BaseQuestionComponent implements OnInit {
  @Input() question$: BehaviorSubject<Question>;
  question: Question;
  @Input() questionDisplay: Question;
  @Input() answerResult$: BehaviorSubject<SubmittedAnswerResult>;
  @Input() answerResultRetrieved$: BehaviorSubject<boolean>;
  @Input() parentLoadingMessage$: BehaviorSubject<string>;
  @Output() answerSubmitted: EventEmitter<any> = new EventEmitter<any>();
  // @ts-ignore - because it's throwing an error on perfectly valid api usage.
  @ViewChild('multiForm') customForm: ElementRef;
  formIsValid = false;
  answerResult: SubmittedAnswerResult;
  answerResultRetrieved = false;
  questionSubmitting$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.answerResultRetrieved$.subscribe(val => {
      if (val === true) {
        this.answerResult$.subscribe(data => {
          this.question = this.question$.value;
          this.answerResultRetrieved = true;
          this.answerResult = data;
          this.setAnswer();
          if (this.questionSubmitting$.value === true) {
            this.toggleQuestionSubmitting();
          }
        });
      }
    });
  }

  toggleQuestionSubmitting(): void {
    this.questionSubmitting$.next(!this.questionSubmitting$.value);
  }

  setAnswer() {
    throw new Error('Components using BaseQuestionComponent must implement setAnswer()');
  }

  handleSubmit(value, isValid) {
    throw new Error('Components using BaseQuestionComponent must implement handleSubmit()');
  }

  answerHasComments(id: number) {
    if (this.answerResult$.value.answerDetails != null) {
      const answer = this.answerResult$.value.answerDetails.find(detail => detail.id === id);
      return answer !== undefined && answer.commentsHtml !== '' && answer.commentsHtml !== null;
    }

    return false;
  }

  showFullQuestion(): boolean {
    return this.answerResultRetrieved$.value;
  }

  answerComments(id: number): string {
    const answer = this.answerResult$.value.answerDetails.find(detail => detail.id === id);

    return answer.commentsHtml;
  }

  canShowCorrectAnswer(): boolean {
    if (this.question$.value == null) {
      return false;
    }
    let unanswered = false;
    if (this.answerResult$.value !== null) {
      unanswered = !this.question$.value.canAnswer && !this.answerResult$.value.allowReattempt;
    }
    else {
      unanswered = !this.question$.value.canAnswer;
    }
    const answered = this.answerResult$.value != null && !this.answerResult$.value.allowReattempt && !this.answerResult$.value.isCorrect;
    if (this.answerResult$.value === null && this.question$.value.isLastChanceEligible === true) {
      return false;
    }
    if (this.question$.value.platformQuizType === 'survey') {
      return false;
    }

    return unanswered || answered;
  }

  getAnswerText(answer: Answer) {
    if (answer.html !== '' && answer.html) {
      return answer.html;
    }
    if (answer.text !== '') {
      return answer.text;
    }
  }
}

@Component({
  selector: 'app-replacement-fields-base-question-component',
  template: ''
})
export class ReplacementFieldsBaseQuestionComponent extends BaseQuestionComponent implements OnInit {
  replacementVariables: ReplacementVariable[] = [];
  formStringParts: string[] = [];
  formString: string;
  multipleForm: UntypedFormGroup;

  ngOnInit(): void {
    super.ngOnInit();
    this.setUp();
    this.formString = new MultiTextPipe()
      .transform(this.formStringParts, this.replacementVariables, this.multipleForm, this.answerResult$.value);
  }

  handleSubmit(value, isValid) {
    throw new Error('Components using BaseQuestionComponent must implement handleSubmit()');
  }

  // This should be implemented, but also must be used in the (change) event handler on the form.
  formListener() {
    throw new Error('Components using ReplacementFieldsBaseQuestionComponent must implement formListener()');
  }

  disableElements() {
    const inputs = this.getElements();
    // @ts-ignore because we're faking an abstract class
    inputs.forEach(i => i.setAttribute('disabled', true));
  }

  getElements() {
    throw new Error('Components using ReplacementFieldsBaseQuestionComponent must implement getElements()');
  }

  setUp(): void {
    this.setReplacementVariables();
    const pattern = this.regexFromReplacementVars();
    this.formStringParts = this.questionDisplay.questionText.split((pattern));
  }

  setReplacementVariables(): void {
    let m: RegExpExecArray;
    const reg = /\[(.*?)]/gi;
    while (m = reg.exec(this.questionDisplay.questionText)) {
      const replacement = new ReplacementVariable();
      replacement.asString = m[1];
      replacement.withBrackets = m[0];
      this.replacementVariables.push(replacement);
    }
    this.replacementVariables.forEach(rv => {
      const answers = this.questionDisplay.answers.filter(a => a.blankId === rv.asString);
      rv.answerOptions = answers;
    });
  }

  regexFromReplacementVars(): RegExp {
    const bracketVars = [];
    this.replacementVariables.forEach(rv => bracketVars.push(rv.withBrackets));
    return new RegExp(
      '(' + bracketVars
        // Escape special characters
        .map(s => s.replace(/[()[\]{}*+?^$|#.,\/\\\s-]/g, '\\$&'))
        // Sort for maximal munch
        // .sort((a, b) => b.length - a.length)
        .join('|') + ')',
      'g'
    );
  }

  isReplacementVariable(check: string): boolean {
    return this.replacementVariables.filter(s => s.withBrackets === check).length > 0;
  }

  getReplacementVariable(variable: string): ReplacementVariable {
    return this.replacementVariables.filter((s => s.withBrackets === variable))[0];
  }

  getReplacementVariableString(check: string) {
    return this.replacementVariables.filter((s => s.withBrackets === check))[0].asString;
  }
}

export class ReplacementVariable {
  asString: string;
  withBrackets: string;
  answerOptions: Answer[];
}
