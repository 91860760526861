<div class="alert alert-warning question-expired" role="alert"
     *ngIf="isExpired$.value === true
     && (answerResultRetrieved$ | async).valueOf()
     && answerResult$.value == null">
  This question expired on {{ expirationDate$.value | momentTz }}.
</div>
  <div *ngIf="questionSubmissionError$.value === true" class="alert alert-danger" role="alert">
    There was an issue submitting your answer.  You can refresh this page to try again or <a href="{{ supportUrl }}">contact support.</a>
  </div>
  <div>
      <div class="question card">
        <div class="card-header">
          <div
            class="card-title text-center question__stem" [innerHTML]="questionDisplay.questionName | safeHtml"
            [ngClass]="{'disabled': isExpired$.value === true && answerResult$.value == null}">
          </div>
        </div>
        <div class="card-body">
          <fieldset [disabled]="isExpired$.value === true">
            <legend class="visually-hidden">Complete:</legend>
            <!--MULTIPLE CHOICE -->
            <ng-container *ngIf="questionDisplay && (questionDisplay.questionType == 'multiple_choice_question' || questionDisplay.questionType == 'true_false_question')">
              <app-multiple-choice-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-multiple-choice-question>
            </ng-container>
            <!--MULTIPLE SELECT -->
            <ng-container *ngIf="questionDisplay?.questionType === 'multiple_answers_question'">
              <app-multiple-answers-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-multiple-answers-question>
            </ng-container>
            <!-- TEXT ONLY -->
            <ng-container *ngIf="questionDisplay?.questionType === 'text_only_question'">
              <app-text-only-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-text-only-question>
            </ng-container>
            <!-- NUMERICAL ONLY -->
            <ng-container *ngIf="questionDisplay?.questionType === 'numerical_question'">
              <app-numeric-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-numeric-question>
            </ng-container>
            <!-- ESSAY QUESTION -->
            <ng-container *ngIf="questionDisplay?.questionType === 'essay_question'">
              <app-essay-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-essay-question>
            </ng-container>
            <!-- SHORT ANSWER QUESTION -->
            <ng-container *ngIf="questionDisplay?.questionType === 'short_answer_question'">
              <app-short-answer-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-short-answer-question>
            </ng-container>
            <!-- MULTIPLE DROPDOWNS QUESTION -->
            <ng-container *ngIf="questionDisplay?.questionType === 'multiple_dropdowns_question'">
              <app-multiple-dropdowns-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-multiple-dropdowns-question>
            </ng-container>
            <!-- MULTIPLE FILL IN THE BLANKS -->
            <ng-container *ngIf="questionDisplay?.questionType === 'fill_in_multiple_blanks_question'">
              <app-multiple-blanks-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-multiple-blanks-question>
            </ng-container>
            <!-- MATCHING QUESTION -->
            <ng-container *ngIf="questionDisplay?.questionType === 'matching_question'">
              <app-matching-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-matching-question>
            </ng-container>
            <!-- FORMULA QUESTION -->
            <ng-container *ngIf="questionDisplay?.questionType === 'calculated_question'">
              <app-formula-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
              ></app-formula-question>
            </ng-container>
            <!-- FILE UPLOAD QUESTION -->
            <ng-container *ngIf="questionDisplay?.questionType === 'file_upload_question'">
              <app-file-upload-question
                (answerSubmitted)="handleSubmit($event)"
                [question$]="question$"
                [answerResultRetrieved$]="answerResultRetrieved$"
                [answerResult$]="answerResult$"
                [questionDisplay]="questionDisplay"
                [parentLoadingMessage$]="loadingMessage$"
                [slug]="slug"
              ></app-file-upload-question>
            </ng-container>
          </fieldset>
          <div id="answerSubmittedScrollTarget"></div>
          <ng-container *ngIf="(answerResultRetrieved$.value && question$.value != null && answerResult$.value != null)">
            <ng-container *ngIf="question$.value.platformQuizType != 'survey'">
              <div *ngIf="answerResult$.value.isCorrect && isAnswerableQuestion() && !needsGrading()" class="alert alert-success alert--answer-correct">
                <div>That is correct!</div>
              </div>
              <div *ngIf="!isAnswerableQuestion() || needsGrading()" class="alert alert-success alert--answer-complete">
                <div>This has been marked complete.</div>
              </div>
              <div *ngIf="isAnswerableQuestion() && !answerResult$.value.isCorrect && answerResult$.value.isCorrect != null" class="alert alert-danger alert--answer-incorrect">
                <div>That is incorrect. <span *ngIf="reAttemptAvailable()">You have one more attempt; please review any comments below and try again.</span> </div>
              </div>
            </ng-container>
            <ng-container *ngIf="question$.value.platformQuizType == 'survey'">
              <div class="alert alert-success alert--answer-complete">
                <div>This has been marked complete.</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="answerResultRetrieved$.value && answerResult$.value != null">
            <div *ngIf="answerResult$.value.neutralComments || answerResult$.value.questionCorrectComments || answerResult$.value.questionIncorrectComments" class="explanation">
              <div
                class="answer-comments alert alert-success alert--comments-correct"
                *ngIf="answerResult$.value.isCorrect && answerResult$.value.questionCorrectComments != null && answerResult$.value.questionCorrectComments != ''"
                [innerHTML]="answerResult$.value.questionCorrectComments | safeHtml">
              </div>
              <div
                class="answer-comments alert alert-danger alert--comments-incorrect"
                *ngIf="!answerResult$.value.isCorrect && answerResult$.value.questionIncorrectComments != null && answerResult$.value.questionIncorrectComments != ''"
                [innerHTML]="answerResult$.value.questionIncorrectComments | safeHtml">
              </div>
              <div
                class="answer-comments alert--comments-neutral"
                *ngIf="answerResult$.value.neutralComments != null && answerResult$.value.neutralComments != ''"
                [innerHTML]="answerResult$.value.neutralComments | safeHtml">
              </div>
          </div>
            <ng-container *ngIf="answerResult$.value != null && reAttemptAvailable()">
              <hr>

              <div *ngIf="reAttemptAvailable()">
                You have one remaining attempt for this question.<br />
                <button (click)="reattemptGo()" class="btn btn-primary question-submit">
                  <span *ngIf="hasAcknowledgeComments()">Acknowledge and </span>Reattempt
                </button>
              </div>
            </ng-container>
          </ng-container>
          <div class="previous-attempt-message" *ngIf="question$.value?.numberAttempts > 0 && answerResultRetrieved$.value && answerResult$.value == null">
            You have already attempted this question, to see your previous submission, <button (click)="sendViewAndGo(previousAttemptPath())" class="btn btn-link p-0 m-0 previous-submission-link">click here</button>
          </div>
        </div>
      </div>
      <p></p>
    <ng-container *ngIf="(answerResult$.value?.remainingAttempts == 0 && !question$.value?.canAnswer)">
      <div class="row justify-content-center pb-4">
        <button (click)="openFeedbackModal()" class="btn btn-primary btn-dashboard margin-top-20">Give Us Your Feedback!</button>
      </div>
      <p></p>
      <div *ngIf="!question$.value?.canAnswer && answerResult$.value?.remainingAttempts == 0" class="row justify-content-center pb-4">
        <a href="{{this.courseDashboardUrl}}" target=”_blank”><button class="btn btn-primary btn-dashboard margin-top-20">View Course Progress</button></a>
      </div>
      <br>
      <div class="justify-content-center whitespace-pre-wrap">
        <span>If you would like to change how you will receive future questions (email or SMS) then </span>
        <a target="_blank" href="{{this.courseDashboardUrl + '/preferences'}}">click here to change your delivery options.</a>
      </div>
      <div class="feedback-submit-acknowledgement" *ngIf="feedbackSubmitted">
        Thank you for your feedback - your comments have been submitted.
      </div>
    </ng-container>
  </div>
