import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApplicationInsightsLoggingService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.applicationInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    // console.log(`logEvent: Sending event ${name} with data: `, properties);
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception, severityLevel });
    if (environment.production === false) {
      console.log(
        `%cQUESTION UI ERROR: ${exception.message}`, 'background-color: #f8d7da; font-weight: bold; color:#721c24; padding: .5rem;'
      );
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message}, properties);
    if (environment.production === false) {
      console.log(`%cQUESTION UI Trace: ${message}`, 'background-color: #f8d7da; font-weight: bold; color:#721c24; padding: .5rem;');
    }
  }
}
