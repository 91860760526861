<div class="card">
  <div class="card-header">
    <div class="card-title">Please rate this question and give any feedback.</div>
  </div>
  <div class="card-body">
    <label><b>Rating</b></label>
    <form (ngSubmit)="feedbackSubmit(feedbackForm)" #feedbackForm="ngForm" novalidate>
      <div class="form-group">
        <div class="rating">
          <input id="star5" name="star" type="radio" [value]="5" class="radio-btn hide" [(ngModel)] = "feedback.rating"/>
          <label for="star5" >☆</label>
          <input id="star4" name="star" type="radio" [value]="4" class="radio-btn hide" [(ngModel)] = "feedback.rating"/>
          <label for="star4" >☆</label>
          <input id="star3" name="star" type="radio" [value]="3" class="radio-btn hide" [(ngModel)] = "feedback.rating"/>
          <label for="star3" >☆</label>
          <input id="star2" name="star" type="radio" [value]="2" class="radio-btn hide" [(ngModel)] = "feedback.rating"/>
          <label for="star2" >☆</label>
          <input id="star1" name="star" type="radio" [value]="1" class="radio-btn hide" [(ngModel)] = "feedback.rating"/>
          <label for="star1" >☆</label>
          <div class="clear"></div>
        </div>
      </div>
      <div class="form-group">
        <label><b>Comments</b></label>
        <textarea class="form-control" [(ngModel)]="feedback.comments"
        placeholder="{{feedback.comments || feedback.comments == '' ? 'Type your comment' : ''}}"
        name="comments"
        rows="6"
        id="comments"
        #ctrl="ngModel">
      </textarea>
      </div>
      <div class="float-right">
        <button
        class="btn btn-link mr-1"
        (click)="onClick()"
        [disabled]="!feedbackForm.valid">
        Cancel</button>
        <button
          type="submit"
          class="btn btn-info"
          [disabled]="!feedbackForm.valid">
          Submit</button>
          </div>
    </form>
  </div>
</div>
