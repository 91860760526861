<ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
  <form [formGroup]="selectForm">
    <ng-container *ngFor="let answer of useQuestion.answers; let i = index">
      <div [ngClass]="{
            'row': true,
            'select--correct': true
      }">
        <div class="col-md-4">
          <label for="select-{{ answer.id.toString() }}">{{ getAnswerText(answer) }}</label>
        </div>
        <div class="col-md-4 form-group">
          <select class="form-select form-control"
                  id="select-{{ answer.id.toString() }}"
                  name="{{answer.id.toString()}}">
            <option value=""> {{ correctAnswerText(answer.matchId)}} </option>
            <ng-container *ngFor="let match of useQuestion['matches']">
              <option
                [disabled]="true"
                [value]="match.matchId"
              >{{ match.text }}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </ng-container>
    <hr />
  </form>
</ng-template>
<ng-template
  [ngTemplateOutlet]="questionFormTemplate"
  [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
></ng-template>
