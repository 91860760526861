import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { QuestionPreviewerComponent } from './question-presenter/components/question/question-previewer.component';
import { LmsQuestionPreviewerComponent } from './question-presenter/components/question/lms-question-previewer.component';
import { QuestionPresenterComponent } from './question-presenter/containers/question-presenter/question-presenter.component';
import {EmailPreferencesContainerComponent} from './email-preferences-container/email-preferences-container.component';
import {PlatformAuthenticationComponent} from './platform-authentication/platform-authentication.component';
import {PlatformQuestionContainerComponent} from './platform-question/platform-question-container/platform-question-container.component';
import { PlatformQuestionPreviewerComponent } from './platform-question/platform-question-previewer/platform-question-previewer.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  {
    path: 'quiz',
    children: [
      { path: '', component: QuestionPresenterComponent },
      { path: ':requestId', component: QuestionPresenterComponent }
    ]
  },
  { path: 'preview/:questionId', component: QuestionPreviewerComponent },
  { path: 'course/:courseId/quizzes/:quizId/questions/:questionId/preview', component: PlatformQuestionPreviewerComponent, pathMatch: 'full'},
  { path: 'question/:slug', component: PlatformQuestionContainerComponent, pathMatch: 'full'},
  { path: 'question/d/:slug', component: PlatformQuestionContainerComponent, pathMatch: 'full'},
  { path: 'preferences/email', component: EmailPreferencesContainerComponent, pathMatch: 'full' },
  { path: 'platform-authentication/init', component: PlatformAuthenticationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
