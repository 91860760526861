import { Component, OnInit } from '@angular/core';
import {EssayQuestionComponent} from "../../../question-types/essay-question/essay-question.component";

@Component({
  selector: 'app-essay-question-previewer',
  templateUrl: './essay-question.component.html',
  styleUrls: ['./essay-question.component.scss']
})
export class EssayQuestionPreviewerComponent extends EssayQuestionComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }

  handleSubmit() {}
}
