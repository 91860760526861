<div class="quiz-error" *ngIf="httpError !== undefined">
  {{ httpError.statusText }}
</div>
<div class="quiz-completion" *ngIf="shouldShowCourseComplete()">
  <app-quiz-completion></app-quiz-completion>
</div>
<div class="question-presenter" *ngIf="question">
  <app-question
    [question]="question"
    [answerResult]="answerResult"
    [isSubmitting]="(isSubmitting$ | async).valueOf()"
    (responseFormSubmit)="handleQuestionResponse($event)" ></app-question>


    <app-question-acknowledge
      (acknowledgementSubmit)="acknowledge()"
      [question]="question">
    </app-question-acknowledge>
  <app-question-footer (feedbackFormSubmitEvent)="handleFeedbackSubmissionEvent($event)" *ngIf="question && shouldShowFooter()" [question]="question"></app-question-footer>
</div>
