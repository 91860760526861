import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {PlatformAuthenticationService} from '../services/platform-authentication.service';
import {OauthService} from '../services/oauth.service';
import {catchError} from 'rxjs/operators';
import {OnBehalfOfService} from '../services/on-behalf-of.service';
import {of, throwError} from 'rxjs';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-platform-authentication',
  templateUrl: './platform-authentication.component.html',
  styleUrls: ['./platform-authentication.component.scss']
})
export class PlatformAuthenticationComponent implements OnInit {
  retrievalMessage = 'Please wait while we retrieve your account.';
  constructor(private router: Router, private oauthService: OauthService, private oboService: OnBehalfOfService) {}

  ngOnInit() {
    const slug = localStorage.getItem('platform-user-slug');
    const redirectTo = `${window.location.origin}${localStorage.getItem('platform-user-redirect')}`;
    const request$ = this.oauthService.confirmLearnerAuthenticated(slug, redirectTo);

    request$.subscribe(
      resp => {
        this.oboService.update(resp);
        localStorage.setItem('platform-user', resp);
        this.router.navigateByUrl(localStorage.getItem('platform-user-redirect'));
      },
      (err) => {
        if (err.status === 404) {
          // TODO - how do we deal with 404? There *should not* be any 404 because that would mean someone
          //  is clicking on a question that isn't related to a user.
          this.retrievalMessage = 'We were unable to retrieve your question.'
          return of(null);
        }
        if (err.status === 401) {
          // User needs to authenticate
          localStorage.setItem('platform-user', err.error);
          window.location.href = `${environment.apiBase}/api/v2/obo/oauth/initiate?QT-OBO=${err.error}`;
          return throwError(new Error('authenticating'));
        }
      }
    );
  }
}
