<div class="question card" *ngIf="question !== undefined">
  <div class="card-header">
    <div
      class="card-title text-center question__stem" [innerHTML]="question.questionName">
    </div>
  </div>
  <div class="card-body">
    <div
      class="question__lead-in"
      [innerHTML]="question.questionText">
    </div>
    <form novalidate>
      <div
        class="form-check"
        *ngFor="let answer of question.answers; index as id">
        <input
          class="form-check-input  {{ answerResult.answer.answer == answer.id ? 'is-correct-answer' : '' }}"
          type="radio"
          name="questionAnswer"
          id="choice-{{ id }}"
          [value]="answer.id"
          [disabled]="true"
          [checked]="answerResult.answer.answer == answer.id"
        />
        <label
          class="form-check-label"
          for="choice-{{ id }}"
          [innerHTML]="getAnswerText(answer)">
        </label>
        <div *ngIf="answerResult.answer.answer == answer.id">
          <div
            class="answer-comments alert alert-success"
            *ngIf="answerResult.isCorrect && answerResult.correctComments != null && answerResult.correctComments != ''"
            [innerHTML]="answerResult.correctComments">
          </div>
          <div
            class="answer-comments alert alert-warning"
            *ngIf="!answerResult.isCorrect && answerResult.incorrectComments != null && answerResult.incorrectComments != ''"
            [innerHTML]="answerResult.incorrectComments">
          </div>
        </div>
      </div>
      <hr>
      <div
        class="answer-comments alert alert-success"
        *ngIf="answerResult.isCorrect && answerResult.questionCorrectComments != null && answerResult.questionCorrectComments != ''"
        [innerHTML]="answerResult.questionCorrectComments">
      </div>
      <div
        class="answer-comments alert alert-danger"
        *ngIf="!answerResult.isCorrect && answerResult.questionIncorrectComments != null && answerResult.questionIncorrectComments != ''"
        [innerHTML]="answerResult.questionIncorrectComments">
      </div>
      <div
        class="answer-comments alert alert-info"
        *ngIf="answerResult.neutralComments != null && answerResult.neutralComments != ''"
        [innerHTML]="answerResult.neutralComments">
      </div>
      <hr>
    </form>
  </div>
</div>
