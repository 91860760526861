<div
  class="question__lead-in"
  [innerHTML]="questionDisplay.questionText | safeHtml">
</div>
<form [formGroup]="selectForm" (ngSubmit)="handleSubmit()">
  <ng-template #questionFormTemplate let-useQuestion="useQuestion" let-questionLoaded="questionLoaded">
    <ng-container *ngFor="let answer of useQuestion.answers; let i = index">
      <div class="row">
        <div class="col-md-4">
          <label for="select-{{ answer.id.toString() }}" [innerHTML]="getAnswerText(answer)"></label>
        </div>
        <div class="col-md-4 form-group">
          <select
            id="select-{{answer.id.toString()}}"
            class="form-select form-control"
            [formControlName]="answer.id.toString()"
          >
            <option value=""> [ Choose ] </option>
            <ng-container *ngIf="questionLoaded">
              <ng-container *ngFor="let match of questionDisplay.matches">
                <option
                  [value]="match.matchId"
                >{{ match.text }}</option>
              </ng-container>
            </ng-container>
          </select>
        </div>
      </div>
      <ng-container *ngIf="canShowCorrectAnswer()">
        <div class="row correct-answer-row">
          <div class="col-md-4"></div>
          <div class="col-md-4 alert alert-success">Correct Answer: {{ correctAnswerText(answer.matchId) }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="answerResult$.value != null">
        <div *ngIf="answerHasComments(answer.id)">
          <div *ngIf="answerResult$.value.answerDetails[0].commentsHtml != '' && answerResult$.value.answerDetails[0].commentsHtml !== null">
            <div
              class="answer-comments alert alert-info"
              [innerHTML]="answerComments(answer.id)">
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template
    *ngIf="!showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: questionDisplay,
      questionLoaded: false
    }"
  ></ng-template>
  <ng-template
    *ngIf="showFullQuestion()"
    [ngTemplateOutlet]="questionFormTemplate"
    [ngTemplateOutletContext]="{
      useQuestion: question$ | async,
      questionLoaded: true
    }"
  ></ng-template>
  <hr />
  <div class="question-answer-actions">
    <button
      *ngIf="answerResult$.value == null && !(questionSubmitting$ | async).valueOf()"
      [disabled]="answerResult$.value != null || !selectForm.valid"
      [ngClass]="{
        'btn': true,
        'btn-primary': true,
        'can-answer': showFullQuestion() && question$.value.canAnswer === true
      }"
      class="btn btn-primary question-submit">Submit</button>
    <app-button-loader
      *ngIf="(questionSubmitting$ | async).valueOf()"
    ></app-button-loader>
  </div>
</form>
