import { SubmittedAnswerAnswer, SubmittedAnswerResult } from '../../../../models/submitted-answer-result';
import { Component } from '@angular/core';
import { BaseQuestionComponent } from 'src/app/platform-question/question-types/base-question/base-question.component';

@Component({
  selector: 'app-formula-question-previewer',
  templateUrl: './formula-question.component.html',
  styleUrls: ['./formula-question.component.scss']
})
export class FormulaQuestionPreviewerComponent extends BaseQuestionComponent {
  handleSubmit() {}
}
