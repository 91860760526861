import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-email-preferences-lookup',
  templateUrl: './email-preferences-lookup.component.html',
  styleUrls: ['./email-preferences-lookup.component.scss']
})
export class EmailPreferencesLookupComponent {
  @Output() emailLookup: EventEmitter<any> = new EventEmitter<any>();
  email: string;
  constructor() { }

  emailLookupSubmit(val, isValid): void {
    if (isValid) {
      this.emailLookup.emit(val);
    }
  }

}
