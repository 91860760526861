import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseQuestionComponent } from '../../../question-types/base-question/base-question.component';

@Component({
  selector: 'app-multiple-answers-question-previewer',
  templateUrl: './multiple-answers-question.component.html',
  styleUrls: ['./multiple-answers-question.component.scss']
})
export class MultipleAnswersQuestionPreviewerComponent extends BaseQuestionComponent implements OnInit {
  multipleAnswersForm: UntypedFormGroup;

  get answersFormArray() {
    return this.multipleAnswersForm.controls.answers as UntypedFormArray;
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.multipleAnswersForm = this.formBuilder.group({
      answers: new UntypedFormArray([])
    });
  }

  ngOnInit() {
    this.question = this.question$.value;
    this.createForm();
  }

  handleSubmit(): void {}

  createForm() {
    this.question.answers.forEach(answer => {
      let formStateValue = false;
      // @ts-ignore
      // Because we need a generic object and in multiple answers we know this to be an array of int.
      if (answer.weight == 100) {
        formStateValue = true;
      }
      this.answersFormArray.push(new UntypedFormControl(formStateValue));
    });
  }

  removeFirstParagraphTag(htmlString: string): string {
    var str = htmlString.replace(/<p>(.*?)<\/p>/, '$1');
    return ' ' + str;
  }
}
