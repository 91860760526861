<app-site-header [canvasAccount]="canvasAccountId"></app-site-header>
<div class="qt-layout-constraint {{ question.canvasAccountId != null ? 'canvas-account-' + question.canvasAccountId : null }}">
  <div class="question card" *ngIf="question !== undefined">
    <div class="card-header">
      <div
        class="card-title text-center question__stem" [innerHTML]="question.questionName">
      </div>
    </div>
    <div class="card-body">
      <div *ngIf="question.questionType !== 'multiple_dropdowns_question'"
        class="question__lead-in"
        [innerHTML]="question.questionText | safeHtml">
      </div>
      <form novalidate>
        <!-- MULTIPLE CHOICE/TRUE FALSE QUESTION -->
        <ng-container *ngIf="question.questionType === 'multiple_choice_question' || question.questionType === 'true_false_question'">
          <app-multiple-choice-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-multiple-choice-question-previewer>
        </ng-container>
        <!-- SHORT ANSWER QUESTION -->
        <div *ngIf="question.questionType === 'short_answer_question'">
          <app-short-answer-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-short-answer-question-previewer>
        </div>
        <!-- NUMERICAL -->
        <div *ngIf="question.questionType === 'numerical_question'">
          <app-numeric-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-numeric-question-previewer>
        </div>
        <!--MULTIPLE ANSWERS -->
        <ng-container *ngIf="question.questionType === 'multiple_answers_question'">
          <app-multiple-answers-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-multiple-answers-question-previewer>
        </ng-container>
        <!-- FILL IN MULTIPLE BLANKS QUESTION -->
        <ng-container *ngIf="question.questionType === 'fill_in_multiple_blanks_question'">
          <app-multiple-blanks-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
            [answerResultRetrieved$]="answerResultRetrieved$"
          ></app-multiple-blanks-question-previewer>
        </ng-container>
        <!-- MATCHING QUESTION>-->
        <ng-container *ngIf="question.questionType === 'matching_question'">
          <app-matching-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-matching-question-previewer>
        </ng-container>
        <!-- FORMULA QUESTION-->
        <ng-container *ngIf="question.questionType === 'calculated_question'">
          <app-formula-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-formula-question-previewer>
        </ng-container>
        <!--MULTIPLE DROP DOWN-->
        <ng-container *ngIf="question.questionType === 'multiple_dropdowns_question'">
          <app-multiple-dropdowns-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
            [questionDisplay]="question"
            [answerResultRetrieved$]="answerResultRetrieved$"
          ></app-multiple-dropdowns-question-previewer>
        </ng-container>
        <!-- TEXT ONLY -->
        <ng-container *ngIf="question.questionType === 'text_only_question'">
          <app-text-only-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-text-only-question-previewer>
        </ng-container>
        <!-- ESSAY -->
        <ng-container *ngIf="question.questionType === 'essay_question'">
          <app-essay-question-previewer
            [question$]="question$"
            [answerResult$]="answerResult$"
          ></app-essay-question-previewer>
        </ng-container>
        <!--FILE UPLOAD-->
        <ng-container *ngIf="question.questionType === 'file_upload_question'">
          <app-file-upload-question-previewer
          ></app-file-upload-question-previewer>
        </ng-container>
        <hr>
        <div *ngIf="question.questionType !== 'text_only_question'">
          <i>Correct, Incorrect, and Neutral comments for this question.</i><p></p>
          <div
            class="answer-comments alert alert-success"
            *ngIf="question.correctCommentsHtml != null && question.correctCommentsHtml != ''"
            [innerHTML]="question.correctCommentsHtml | safeHtml">
          </div>
          <div
            class="answer-comments alert alert-success"
            *ngIf="question.correctCommentsHtml == null && question.correctCommentsHtml == '' && question.correctComments != null && question.correctComments != ''"
            [innerHTML]="question.correctComments | safeHtml">
          </div>
          <div
            class="answer-comments alert alert-danger"
            *ngIf="question.incorrectCommentsHtml != null && question.incorrectCommentsHtml != ''"
            [innerHTML]="question.incorrectCommentsHtml | safeHtml">
          </div>
          <div
            class="answer-comments alert alert-danger"
            *ngIf="question.incorrectCommentsHtml == null && question.incorrectCommentsHtml == '' && question.incorrectComments != null && question.incorrectComments != ''"
            [innerHTML]="question.incorrectComments | safeHtml">
          </div>
          <div
            class="answer-comments alert--comments-neutral"
            *ngIf="question.neutralCommentsHtml != null && question.neutralCommentsHtml != ''"
            [innerHTML]="question.neutralCommentsHtml | safeHtml">
          </div>
          <div
            class="answer-comments alert--comments-neutral"
            *ngIf="question.neutralCommentsHtml == null && question.neutralCommentsHtml == '' && question.neutralComments != null && question.neutralComments != ''"
            [innerHTML]="question.neutralComments | safeHtml">
        </div>
      </div>
      <hr>
      </form>
    </div>
  </div>
</div>
