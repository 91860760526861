import { Component, Input, OnInit } from '@angular/core';
import { HateOasService } from '../../../shared/utilities/hateoas';
import { Question } from '../../../models/question';
import { QuestionStatisticsService } from '../../../services/question-statistics/question-statistics-service';
import { QuestionStatistics } from 'src/app/models/question-statistics';
// import { Color } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-question-statistics',
  templateUrl: './question-statistics.component.html',
  styleUrls: ['./question-statistics.component.scss']
})
export class QuestionStatisticsComponent implements OnInit {

  @Input() question: Question;

  statistics: QuestionStatistics = new QuestionStatistics();

  public questionStatisticsArray: QuestionStatistics[]
  public starStyle: SafeStyle = undefined;
  public chartLabels = [];
  public chartData = [];
  public chartType = 'doughnut';
  // public options: ChartOptions = { legend: { display: true } };
  public options: ChartOptions = {};
  public colors: [];
  // public colors: Color[] = [

  //   {
  //     backgroundColor: [
  //       '#DC3912',
  //       '#3366CC',
  //       '#FF9900',
  //       '#109618',
  //       '#990099',
  //     ]
  //   }
  // ];

  constructor(
    private hateoas: HateOasService,
    private questionStatisticsService: QuestionStatisticsService,
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    ) {}

  ngOnInit(): void {
    const getLink = this.hateoas.getLinkInterfaceForRel(this.question.links, 'Get_QuestionStats');

    this.questionStatisticsService.getQuestionStatistics(getLink.href).subscribe((data: QuestionStatistics) => {
      if (data == null) {
        return;
      }

      this.statistics = data;
      if (this.statistics.ratingAverage !== undefined && this.statistics.ratingAverage > 0) {
        this.statistics.ratingAverage = Math.ceil(this.statistics.ratingAverage * 100) / 100;
        this.starStyle = this.sanitizer.bypassSecurityTrustStyle('--rating: ' + this.statistics.ratingAverage);
      }

      let choiceNumber = 1;
      for (const statistic of this.statistics.answerStatistics) {
        if (this.stripHtml(statistic.answer) == ' ' || this.stripHtml(statistic.answer) == '' ) {
          this.chartLabels.push('Choice ' + choiceNumber)
          choiceNumber++;
        }
        else {
          this.chartLabels.push(this.stripHtml(statistic.answer))
          choiceNumber++;
        }

        this.chartData.push(statistic.count);
      }
    });
  }

  stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
  onClick(): void {
    this.activeModal.close('closed');
  }

}

